import React from "react";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import ShopCard from "../../../components/grids/ShopCard";
import AddNewShop from "../../../components/forms/NewShopForm";
import HeaderChannel from "../../../components/HeaderChannel";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRegionAction,
  getAllShopAction,
} from "../../../store/channel/actions";
import { Box, Skeleton } from "@mui/material";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import SearchInput from "../../../components/buttons/SearchButton";
import ExportCSVFile from "../../../components/buttons/ExportCSVFile";
import { AllShopsDataHeaders } from "../../../components/csvHeaders/Headers";
import { myChannelActions } from "../../../store/channel";

const Shops = () => {
  const { auth, channel, layout } = useSelector((state: any) => state);
  const [searchKey, setSearchKey] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /* This state section is For Paginations */
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(
    ["sales-supervisor"].includes(auth?.user?.role?.toLowerCase()) ? 50 : 15
  );

  const dispatch = useDispatch();
  const channelId = channel?.selected?._id;

  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllShopAction(
        auth?.token,
        `channel=${channelId}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    dispatch(myChannelActions.setDeviceWithCommission(null));
  }, [auth?.token, channelId, page, limit, dispatch]);

  React.useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        channelId &&
        getAllShopAction(
          auth?.token,
          `channel=${channelId}&limit=${limit}&page=${page - 1}`
        )(dispatch);
    }
  }, [searchKey]);

  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllRegionAction(auth?.token, `channel=${channelId}`)(dispatch);
  }, [dispatch, auth?.token, channelId]);

  const data = channel?.allShop?.data?.map((el: any) => {
    if (
      !auth?.user?.regions?.includes(el?._id) &&
      ["sales-supervisor"].includes(auth?.user?.role)
    )
      return null;
    return {
      id: el._id,
      name: el.name,
      address: el.address,
      msisdn: el.msisdn,
      momocode: el.momocode,
      merchantName: el.merchantName,
      status: el.status,
      type: el.type,
      data: el,
      region: el.region,
      // agents: [{ name: el.agent.fname, phone: el.agent.phone }],
    };
  });

  const handleSearch = () => {
    auth?.token &&
      channelId &&
      getAllShopAction(
        auth?.token,
        `channel=${channelId}&limit=${limit}&page=${page - 1}&sk=${searchKey}`
      )(dispatch);
  };

  const csvData = channel?.allShop?.data?.map((record: any) => ({
    name: record?.name || "N/A",
    type: record?.type?.split("-")?.join(" ") || "N/A",
    address: record?.address?.split("-")?.join(" ") || "N/A",
    region: record?.region?.split("-")?.join(" "),
  }));

  return (
    <>
      <div className="mt-4 text-black ">
        <div className="flex items-center justify-between px-4 pt-5">
          <DetailsHeaderActionBar pageName="Shop" title=" " />
          {["admin", "finance-manager", "dev"]?.includes(
            auth?.user?.role?.toLowerCase()
          ) && (
            <HeaderChannel
              item="Shop"
              modelTitle=""
              isNotCollapse={true}
              ModelComponent={
                <AddNewShop id={channelId} onCancel={handleCancel} />
              }
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              handleCancel={handleCancel}
            />
          )}
        </div>
        <div className="flex items-center justify-end gap-5 mt-5 px-4">
          <SearchInput
            onSearch={handleSearch}
            onChange={(e: any) => {
              e.preventDefault();
              setSearchKey(e.target.value);
            }}
          />
          <ExportCSVFile
            csvHeaders={AllShopsDataHeaders}
            csvData={csvData || []}
            filename={`Shops.csv`}
          />
        </div>
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.allShop?.total}
          count={Math.ceil(channel?.allShop?.total / limit)}
        >
          {channel?.isFetching && (
            <div
              className={`grid ${
                layout?.isSideNavOpen
                  ? "sm:grid-cols-1 lg:grid-cols-2"
                  : "sm:grid-cols-2 lg:grid-cols-3"
              } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
            >
              {Array.from({ length: 12 }).map((d: any) => (
                <Box sx={{}}>
                  <Skeleton animation="wave" />
                  <Skeleton variant="rectangular" height={110} />
                </Box>
              ))}
            </div>
          )}
          {!channel?.isFetching && (
            <div
              className={`grid ${
                layout?.isSideNavOpen
                  ? "sm:grid-cols-1 lg:grid-cols-2"
                  : "sm:grid-cols-2 lg:grid-cols-3"
              } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
            >
              {data?.map((shop: any, _index: number) => {
                if (shop === null) return null;
                return <ShopCard data={shop} />;
              })}
            </div>
          )}
        </ScrollableFrame>
      </div>
    </>
  );
};

export default Shops;
