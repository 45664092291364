import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const AddDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault`, data, token);
};

export const AddcsvfileService = async (data: any, token: string) => {
  return await HttpRequest.upload(`${SERVER_URL}/vault/upload`, data, token);
};

export const lockDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/lock`, data, token);
};
export const unLockDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/unlock`, data, token);
};
export const reloadDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/reload`, data, token);
};
export const releaseDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/release`, data, token);
};

export const sendMessageService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/message`, data, token);
};
export const getDeviceDetailsService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/devices`, token, data);
};
export const getDeviceStatusService = async (token: string, query?: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/vault/statusCount${query}`,
    token
  );
};




export const getDevicePieChartstatsService = async (
  token: string,
  query?: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/vault/statusCount${query}`,
    token
  );
};

export const getOverviewCountService = async (token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/`, token);
};
export const getNotesServices = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/note${query}`, token);
};
export const getDeviceStatus = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault${query}`, token);
};
export const getMessageTamplateService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/vault/messageTemplate${query}`, token);
};
export const getDeviceServiceStatus = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/statusCount${query}`, token);
};
export const getVaultStats = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/vaultstatistics${query}`, token);
};
export const getVaultCustomerService = async (token: any, vaultId: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/vault/customerinformation/${vaultId}`,
    token
  );
};
export const getAllDeviceService = async (token: any, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/allDevices${query}`, token);
};


