import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getAllPaymentHistoryService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/history/${query}`, token);
};
export const getAllNoSaleService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/nosale${query}`, token);
};
export const getTotalRevenueService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/revenue${query}`, token);
};
export const getRevenueQuantityService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/statistics/revenueQuantity${query}`,
    token
  );
};


export const getRecentPaymentService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/statistics/payment${query}`,
    token
  );
};

export const getLiveStockAtShopService = async (token: string,query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/stockByshop${query}`, token);
};

export const getLiveStockAtWarehouseService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/warehouseBrands?brand=$product.brand`, token);
};

export const getDevicesFinancedUnderChannelsService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/channelDevices`, token);
};


export const getDevicesFinancedUnderBusinessProductsService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/devices`, token);
};





export const getStatsPlanService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/plan`, token);
};


