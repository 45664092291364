import React, { useEffect, useState } from "react";
import Statistics from "./Statistics";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSaveTheChildrenAction,
  getTransactionByCustomersReportAction,
} from "../../../store/report/actions";
import {
  CustomerTransactionReportHeaders,
  CustomerTransactionReportSumaryHeaders,
} from "../../../components/csvHeaders/Headers";
import dayjs, { Dayjs } from "dayjs";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import {
  getAllSaveTheChildrenReport_CSVAction,
  getTransactionGroupedByCustomer_CSVAction,
} from "../../../store/csvDownload/actions";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import SavetheChildrentable from "../../../components/tables/saveTheChildrenTable/SavetheChildrentable";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";
import CustomerTransactionTable from "../../../components/tables/report/customerTransactionTable";

const { RangePicker } = DatePicker;

const OverView = () => {
  const { auth, layout, CSV, report } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const currentYear = dayjs().year();
  const defaultRange: [Dayjs, Dayjs] = [
    dayjs().startOf("month"),
    dayjs(`${currentYear}-24`).endOf("month"),
  ];
  const firstDate = dayjs().startOf("month").format("YYYY-MM");
  const secondDate = dayjs(`${dayjs().year()}-24`).format("YYYY-MM");

  const [selectedMonth, setSelectedMonth] = useState<string[]>([
    firstDate,
    secondDate,
  ]);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const channelId = auth?.user?.channel?.at(0)?._id;
  const [value, setValue] = useState("not-extracted");
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      if (value === "not-extracted") {
        getTransactionGroupedByCustomer_CSVAction(
          auth?.token,
          `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
            selectedStatus ? `&status=${selectedStatus}` : ""
          }&limit=${report?.customerTransaction?.total}&page=0${
            channelId ? `&channel=${channelId}` : ""
          }`
        )(dispatch);
      } else if (value === "extracted") {
        getAllSaveTheChildrenReport_CSVAction(
          auth?.token,
          `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
            selectedStatus ? `&status=${selectedStatus}` : ""
          }&limit=${report?.saveTheChildren?.total}&page=0${
            channelId ? `&channel=${channelId}` : ""
          }${report?.searchResult ? `&customer=${report?.searchResult}` : ""}`
        )(dispatch);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  useEffect(() => {
    if (auth?.token)
      getTransactionByCustomersReportAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }&limit=${limit}&page=0${channelId ? `&channel=${channelId}` : ""}`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    selectedMonth,
    selectedStatus,
    channelId,
    limit,
    page,
  ]);

  useEffect(() => {
    if (auth?.token)
      getAllSaveTheChildrenAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }&limit=${limit}&page=${page - 1}${
          channelId ? `&channel=${channelId}` : ""
        }`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    selectedMonth,
    selectedStatus,
    channelId,
    limit,
    page,
  ]);

  const csvDataExtracted =
    CSV?.csvDownloadedData?.data &&
    Array.isArray(CSV?.csvDownloadedData?.data?.list) &&
    CSV?.csvDownloadedData?.data?.list?.map((el: any) => {
      return {
        mPayment: el?.amountToBePaid,
        mPaid: el?.amountPaid,
        transactionId: el?.transaction,
        mPaidremain: el?.amountToBePaid - el?.amountPaid,
        customerName: el?.account?.customer?.name,
        accountType: el?.account?.type,
        paidAt:
          el?.payment?.amount > 0 ? el?.payment?.date?.slice(0, 10) : "N/A",
        customerTel: el?.account?.customer?.phone,
        date: dayjs(el?.dueDate).format("YYYY-MM-DD HH:mm:ss"),
        status: el?.status,
      };
    });

  const csvDataSummary =
    value === "not-extracted" &&
    CSV?.csvDownloadedData?.data?.map((item: any) => {
      return {
        amountPaid: item?.AmountPaid,
        amountToBePaid: item?.amountToBePaid,
        AmountRemain: item?.AmountRemain,
        endingDate: item?.endingDate
          ? dayjs(item?.endingDate).format("YYYY-MM-DD")
          : "N/A",
        customer: item?.customer?.name,
        phone: item?.customer?.phone,
      };
    });

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setSelectedMonth(dateStrings);
    }
  };

  return (
    <div className="mt-8 px-4">
      <div className="flex items-center justify-between mb-2.5 text-black">
        <div className="flex flex-row items-center text-[#0F0F47] pb-4">
          <DetailsHeaderActionBar
            pageName={"Report"}
            goBack={goBack}
            title="Loan Book"
          />
        </div>
        <RangePicker
          picker="month"
          onChange={onRangeChange}
          allowClear={false}
          className="h-10"
          defaultValue={defaultRange}
        />
      </div>
      <div className="flex flex-col space-y-10">
        <Statistics />

        <div className="flex justify-end gap-4">
          <Radio.Group onChange={onChange} value={value} className="mt-2">
            <Radio value={"not-extracted"}>Summary</Radio>
            <Radio value={"extracted"}>Extracted</Radio>
          </Radio.Group>
          {value === "not-extracted" ? (
            <CSVExportButton
              csvHeaders={CustomerTransactionReportSumaryHeaders}
              csvData={csvDataSummary}
              filename={`Transaction_Report_Summary.csv`}
            />
          ) : (
            <CSVExportButton
              csvHeaders={CustomerTransactionReportHeaders}
              csvData={csvDataExtracted}
              filename={`Transaction_Report_extracted.csv`}
            />
          )}
        </div>

        {value === "not-extracted" ? (
          <CustomerTransactionTable
            setPage={setPage}
            limit={limit}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            setLimit={setLimit}
          />
        ) : (
          <SavetheChildrentable
            setPage={setPage}
            limit={limit}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            setLimit={setLimit}
            startMonth={selectedMonth[0]}
            endMonth={selectedMonth[1]}
            status={selectedStatus}
            channelId={channelId}
          />
        )}
      </div>
    </div>
  );
};

export default OverView;
