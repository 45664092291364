import React, { useState } from "react";
import { Col, Divider, Drawer, Row, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateRepairStatusAction } from "../../../store/repair/actions";
import { useNavigate } from "react-router-dom";
import RepairModal from "../../../components/Modals/RepairModel";
import DelivererIn from "../components/DelivererIn";
import DelivererOut from "../components/DelivererOut";
import DeviceIssues from "../components/DeviceIssues";

interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="flex items-center justify-between w-full">
    <p className="font-semibold text-sm w-2/5">{title}</p>
    <span className="w-3/5">{content}</span>
  </div>
);

const RepairDrower: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
  dataToUpdate: any;
  setDataToUpdate: any;
}> = ({ open, onClose, data, setDataToUpdate, dataToUpdate }) => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModelOnSite, setOpenModelOnSite] = useState(false);
  const [openOfficeModel, setOpenOfficeModel] = useState(false);
  const [checked, setChecked] = useState<any>([]);

  const dataId = data?.key || data?._id;

  const showModalOnSite = () => {
    setOpenModelOnSite(true);
  };

  const showModalOffice = () => {
    setOpenOfficeModel(true);
  };

  const handleCancelOnSite = () => {
    setOpenModelOnSite(false);
  };

  const handleCancelOffice = () => {
    setOpenOfficeModel(false);
  };

  const handlerFixedOnSite = async () => {
    await updateRepairStatusAction(auth.token, dataId, {
      status: "repairing",
      repairedAt: "shop",
    })(dispatch);
    setOpenModelOnSite(false);
    onClose();
    navigate("/repairs");
  };

  const handlerFixedOnOffice = async () => {
    await updateRepairStatusAction(auth.token, dataId, {
      status: "send-to-office",
      repairedAt: "office",
    })(dispatch);
    setOpenOfficeModel(false);
    onClose();
    navigate("/repairs");
  };

  return (
    <>
      <Drawer
        width={600}
        placement="right"
        closable={false}
        onClose={() => {
          onClose();
          setChecked([]);
        }}
        title={
          <h1 className="text-[#030229] text-base font-medium">
            Repair Details
          </h1>
        }
        open={open}
        style={{ color: "black" }}
      >
        <p className="text-[#030229] text-base font-medium pb-2">Customer</p>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Name</p>
          <p className="text-[#030229] text-sm">
            {data?.customer ?? data?.cartItemId?.cart?.customer?.name}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">National ID</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.cart?.customer?.nid ??
              data?.cartItemId?.cart?.customer?.nid}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Telephone</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.cart?.customer?.phone ??
              data?.cartItemId?.cart?.customer?.phone}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Email</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.cart?.customer?.email ??
              data?.cartItemId?.cart?.customer?.email}
          </p>
        </div>
        <Divider dashed/>
        <p className="text-[#030229] text-base font-medium py-2">Device</p>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Type</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.shopStockItem?.requestedItem?.product
              ?.product?.type ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                ?.type}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Brand</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.shopStockItem?.requestedItem?.product
              ?.product?.brand ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                ?.brand}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Model</p>
          <p className="text-[#030229] text-sm">
            {data?.model ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                ?.model}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Specification</p>
          <p className="text-[#030229] text-sm">
            {data?.specifications ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
                ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
                ?.join(", ")}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">IMEI</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.payment?.amount?.toLocaleString() ??
              data?.cartItemId?.payment?.amount?.toLocaleString()}
          </p>
        </div>
        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Price</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.payment?.amount?.toLocaleString() ??
              data?.cartItemId?.payment?.amount?.toLocaleString()}
          </p>
        </div>
        {data?.data?.cartItemId?.payment?.extendedWarranty > 0 && (
          <div className="flex justify-between py-1">
            <p className="text-[#030229B2] text-sm">Extended Warranty</p>
            <p className="text-[#030229] text-sm">
              {data?.data?.cartItemId?.payment?.extendedWarranty}
            </p>
          </div>
        )}

        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Service Center</p>
          <p className="text-[#030229] text-sm">
            {data?.cartItemId?.shopStockItem?.shop?.name ?? data?.shop}
          </p>
        </div>

        <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Agent</p>
          <p className="text-[#030229] text-sm">
            {data?.data?.cartItemId?.cart?.createdBy?.names ??
              data?.cartItemId?.createdBy?.names}
          </p>
        </div>
        <Divider dashed/>
        <p className="text-[#030229] text-base font-medium py-2">Issues</p>
        <div className="flex flex-col">
          <DeviceIssues
            issues={data?.data}
            setDataToUpdate={setDataToUpdate}
            dataToUpdate={dataToUpdate}
            setChecked={setChecked}
            checked={checked}
          />
        </div>

        <div className="pt-5">
          <p className="text-[#030229] text-sm">Comment</p>
          <p className="text-[#030229] text-sm mt-4 border border-[#03022924] rounded-md p-4">
            {data?.data?.comment ?? data?.comment}
          </p>
        </div>

        <Divider dashed/>
        {["done", "completed", "transit-in", "transit-out"]?.includes(
          data?.status
        ) && <DelivererIn data={data?.data?.delivererIn} />}

        {["done", "completed", "transit-out"]?.includes(data?.status) && (
          <DelivererOut data={data?.data?.delivererOut} />
        )}

        {data?.status === "pending" && (
          <div className="space-x-4">
            <Button
              className="bg-[#605BFF] border-none outline-none text-white"
              onClick={showModalOnSite}
            >
              Fixed on Site
            </Button>
            <Button
              style={{ color: "#212121" }}
              className="bg-[#DC9E000D] border-none outline-none"
              onClick={showModalOffice}
            >
              Fixed on Office
            </Button>
          </div>
        )}
      </Drawer>
      <RepairModal
        title="On-Site Repair"
        openModel={openModelOnSite}
        onOk={handlerFixedOnSite}
        onCancel={handleCancelOnSite}
      />
      <RepairModal
        title="Repaired At Office"
        openModel={openOfficeModel}
        onOk={handlerFixedOnOffice}
        onCancel={handleCancelOffice}
      />
    </>
  );
};

export default RepairDrower;
