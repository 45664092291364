import React from "react";
import Completed from "../../../assets/images/subscription/completed.png";
import Pending from "../../../assets/images/subscription/pending.png";
import Inactive from "../../../assets/images/subscription/inactive.png";
import Cards from "../subscription/overview/Cards";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Statistics = () => {
  const { report } = useSelector((state: any) => state);
  return (
    <div
      className={`grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-x-4 text-black`}
    >
      <Cards
        cardIcon={Completed}
        value={
          report?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            report?.saveTheChildren?.data?.counts?.paid
          )
        }
        status="Full Paid"
      />
      <Cards
        cardIcon={Pending}
        value={
          report?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            report?.saveTheChildren?.data?.counts?.pending
          )
        }
        status="Pending"
      />
      <Cards
        cardIcon={Inactive}
        value={
          report?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            report?.saveTheChildren?.data?.counts?.late
          )
        }
        status="Overdue"
      />
    </div>
  );
};
export default Statistics;
