import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateSubRequestAction } from "../../store/subscription/subscriptionRequest/actions";
import { submitUpdateNotesAction } from "../../store/account/actions";
import { myLayoutActions } from "../../store/layout";
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const RejectSubscriptionReqForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { auth, subscriptionRequest, account } = useSelector(
    (state: any) => state
  );

  const onFinish = async (values: any) => {
    if (props?.accountId) {
      props?.requestId &&
        (await submitUpdateNotesAction(
          props?.accountId as string,
          {
            notes: {
              title: " Reject Subscription Request",
              details: values?.rejectSubReason,
            },
          },
          auth.token
        )(dispatch));
      await updateSubRequestAction(
        auth?.token,
        props?.requestId,
        {
          ...values,
          status: "rejected",
        },
        "?status=requested&limit=10&page=0"
      )(dispatch);
      dispatch(myLayoutActions.setSubReqStatus("requested"));
      notification.success({ message: "Request has been Rejected" });
      form.resetFields();
      props?.onCancel();
      props?.onCloseDrower();
    }
  };
  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={props?.data || {}}
        style={{ minWidth: 600 }}
        scrollToFirstError
      >
        <h1 className="font-medium text-[16px] text-[#03022995] pb-4 pl-14">
          Reject Subscription Request
        </h1>
        <div className="ml-12">
          <Form.Item
            name="rejectSubReason"
            rules={[
              {
                required: true,
                message: "Reason is required!",
                whitespace: true,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Reason" />
          </Form.Item>
          <Form.Item>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "60%" }}
              loading={subscriptionRequest?.isFetching || account?.isFetching}
            >
              Submit
            </LoadingButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default RejectSubscriptionReqForm;
