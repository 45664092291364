import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";
import { useDispatch, useSelector } from "react-redux";
import { getEbmSelectOptionAction } from "../../store/action";
import { addEbmSalesAction } from "../../store/SalesManagement/actions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getNameByCode } from "../../utils/ConvertCodeToName";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const SalesRegistrationForm = (props: any) => {
  const { ebmSales, auth, ebmGeneral } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const [saleTypeCode, setSaleTypeCode] = useState(null);
  const [saleStatusCode, setSaleStatusCode] = useState(null);
  const [paymentTypeCode, setPaymentTypeCode] = useState(null);
  const [receiptTypeCode, setReceiptTypeCode] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    getEbmSelectOptionAction(auth?.token, "")(dispatch);
  }, []);
  const SalesType =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Transaction Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const SalesStatus =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Sale Status"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const PaymentType =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Payment Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const ReceiptType =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Sales Receipt Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  const TaxOptions =
    ebmGeneral?.selectOption &&
    ebmGeneral?.selectOption["Taxation Type"]?.map((el: any) => ({
      value: el?.value,
      label: el?.label,
    }));
  useEffect(() => {
    form.setFieldsValue({
      nid: ebmSales?.ebmCartItem?.data?.customer?.nid,
      invoiceId: ebmSales?.ebmCartItem?.data?.cartId,
      customerName: ebmSales?.ebmCartItem?.data?.customer?.name,
      amount: ebmSales?.ebmCartItem?.data?.payment?.amount,
      itemTyCd:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption["Item Type"],
          ebmSales?.ebmCartItem?.data?.warehouseProduct?.product?.ebm.itemTyCd
        ),
      itemClsCd:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption?.itemClassList,
          ebmSales?.ebmCartItem?.data?.warehouseProduct?.product?.ebm.itemClsCd
        ),
      qtyUnitCode:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption["Quantity Unit"],
          ebmSales?.ebmCartItem?.data?.warehouseProduct?.product?.ebm
            .qtyUnitCode
        ),
    });
  }, [ebmSales?.ebmCartItem?.data]);
  const addSalesToEbm = async (values: any) => {
    auth?.token &&
      (await addEbmSalesAction(auth?.token, {
        cartId: ebmSales?.ebmCartItem?.data?._id,
        salesTypeCode: saleTypeCode,
        receiptTypeCode: receiptTypeCode,
        paymentTypeCode: paymentTypeCode,
        salesStatusCode: saleStatusCode,
        // purchaserOrderCode: "315963"
      })(dispatch));
    form.resetFields();
    props?.onClose();
  };
  return (
    <Form
      initialValues={ebmSales?.ebmCartItem?.data}
      form={form}
      onFinish={addSalesToEbm}
      layout="horizontal"
      style={{ width: "100%" }}
    >
      <div className="flex gap-5 w-full">
        <div className="w-1/2">
          <div className="flex gap-6 my-3">
            <span className="text-[12px] text-[#030229] pt-2">Sale Type:</span>
            <Select
              showSearch
              style={{ width: 400, height: 40, marginLeft: "1.7rem" }}
              placeholder="Sale Type"
              optionFilterProp="children"
              onChange={(value: any) => setSaleTypeCode(value)}
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input.toString())
              }
              
              options={SalesType}
            />
          </div>
          <div className="flex mt-2">
            <span className="text-[12px] text-[#030229] pt-2 ">
              Sale Status:
            </span>
            <Select
              showSearch
              style={{ width: 400, height: 40, marginLeft: "2.6rem" }}
              placeholder="Sale status"
              optionFilterProp="children"
              onChange={(value: any) => setSaleStatusCode(value)}
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input.toString())
              }
              
              options={SalesStatus}
            />
          </div>
          <div className="flex  mt-2 my-3">
            <span className="text-[12px] text-[#030229] pt-2 ">
              Payment Type:
            </span>
            <Select
              showSearch
              style={{ width: 400, height: 40, marginLeft: "1.5rem" }}
              placeholder="Payment type"
              optionFilterProp="children"
              onChange={(value: any) => setPaymentTypeCode(value)}
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input.toString())
              }
              
              options={PaymentType}
            />
          </div>
          <div className="flex my-3">
            <span className="text-[12px] text-[#030229] pt-2">
              Reciept Type:
            </span>
            <Select
              showSearch
              style={{ width: 400, height: 40, marginLeft: "2rem" }}
              placeholder="Receipt type"
              optionFilterProp="children"
              onChange={(value: any) => setReceiptTypeCode(value)}
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input.toString())
              }
             
              options={ReceiptType}
            />
          </div>
          <Form.Item label="Invoice ID" name="invoiceId">
            <Input className="h-10 w-[25rem] ml-10" />
          </Form.Item>
          <Form.Item name="nid" label="Customer ID">
            <Input className="h-10 w-[25rem] ml-6" />
          </Form.Item>

          <Form.Item label="Customer Name" name="customerName">
            <Input className="h-10 w-[25rem]" />
          </Form.Item>
          <Form.Item label="Sale Date">
            <DatePicker
              className="w-[25rem] h-10 ml-10"
              defaultValue={dayjs(
                ebmSales?.ebmCartItem?.data?.paidOn?.substring(0, 10),
                dateFormat
              )}
            />
          </Form.Item>
         
          <Form.Item label="Total Amount" name="amount">
            <Input className="h-10 w-[25rem] ml-[1rem]" />
          </Form.Item>

          <Form.Item label="Remark" name={"sale"}>
            <Input className="h-10 w-[25rem] ml-[3.2rem]" />
          </Form.Item>
        </div>
        <div className="w-1/2">
          <Form.Item label="VAT" name={"sale"}>
            <Input className="h-10  w-[25rem] ml-[4.3rem]" />
          </Form.Item>
          <Form.Item label="Purchase Code" name={"sale"}>
            <Input className="h-10  w-[25rem]" />
          </Form.Item>
          <Form.Item label="Item Code" name="itemTyCd">
            <Input className="h-10  w-[25rem] ml-[1.82rem]" />
          </Form.Item>
          <Form.Item label="Class Code" name="itemClsCd">
            <Input className="h-10  w-[25rem] ml-6" />
          </Form.Item>
          <div className="w-full flex gap-2">
            <div className="w-1/2">
              <Form.Item label="Unit Price" name={"sale"}>
                <Input className="h-10 w-[10rem] ml-6" />
              </Form.Item>
              <Form.Item>
                <div className="flex gap-9">
                  <span className="text-[12px] text-[#030229] pt-2">
                    Tax Type:
                  </span>
                  <Select
                    showSearch
                    style={{ width: 160, height: 40 }}
                    placeholder="Tax Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .includes(input.toString())
                    }
                    // filterSort={(optionA, optionB) =>
                    //   (optionA?.label ?? "")
                    //     .toLowerCase()
                    //     .localeCompare((optionB?.label ?? "").toLowerCase())
                    // }
                    options={TaxOptions}
                  />
                </div>
              </Form.Item>
              <Form.Item label="D/C Rate (%)" name={"sale"} >
                <Input className="h-10 w-[10rem]" />
              </Form.Item>
              <Form.Item label="sales Price" name={"sale"}>
                <Input className="h-10 w-[10rem] ml-[.9rem]" />
              </Form.Item>
            </div>
            <div className="w-1/2">
              <Form.Item label="Safety Qty" name="qtyUnitCode">
                <Input className="h-10 w-[10rem] ml-4" />
              </Form.Item>
              <Form.Item label="VAT" name={"sale"}>
                <Input className="h-10 w-[10rem] ml-[3.4rem]" />
              </Form.Item>
              <Form.Item label="D/C Amount" name={"sale"}>
                <Input className="h-10 w-[10rem]" />
              </Form.Item>
              <Form.Item label="Total Price" className="w-full">
                <InputNumber className="w-[10rem] h-10 ml-[.8rem]" />
              </Form.Item>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex gap-5">
              <Form.Item>
                <CustomButton
                  title={"Clear"}
                  bgColor="[#f6665925]"
                  width="full"
                  textColor="red-500"
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  title={"Confirm"}
                  bgColor="[#605BFF]"
                  width="full"
                  textColor="white"
                />
              </Form.Item>
            </div>
            <Form.Item>
              <CustomButton 
                title={"Remove"}
                bgColor="[#64628836]"
                width="full"
                textColor="black"
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <Form.Item className="w-1/2 m-auto">
        <CustomButton
          title={
            ebmSales?.isFetching ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              "Save"
            )
          }
          bgColor="[#605BFF]"
          width="full"
          textColor="white"
          icon={<SaveIcon />}
        />
      </Form.Item>
    </Form>
  );
};

export default SalesRegistrationForm;
