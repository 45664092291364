import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "shop",
  initialState: {
    isFetching: false,
    isOverviewFetching: false,
    all: { data: [] },
    selected: null,
    allAgents: null,
    selectedAgent: null,
    allSales: null,
    selectedSale: null,
    allStockRequests: null,
    selectedStockRequest: null,
    allStockItems: null,
    selectedStockItem: null,
    myShop: null,
    adjustStock: null,
    shopTransferFrom: null,
    shopEligible: null,
    shopStat: null,
    shopOverview: null,
    allRegions:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setAgents(state, action) {
      state.allAgents = action.payload;
    },
    setAllSales(state, action) {
      state.allSales = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelectedRequestedStock(state, action) {
      state.selectedStockRequest = action.payload;
    },
    setMyShop(state, action) {
      state.myShop = action.payload;
    },
    setAdjustStock(state, action) {
      state.adjustStock = action.payload;
    },
    setShopTransferFrom(state, action) {
      state.shopTransferFrom = action.payload;
    },
    setShopEligible(state, action) {
      state.shopEligible = action.payload;
    },
    setShopStat(state, action) {
      state.shopStat = action.payload;
    },
    setShopOverview(state, action) {
      state.shopOverview = action.payload;
    },
    setIsOverviewFetching(state, action) {
      state.isOverviewFetching = action.payload;
    },
    setAllRegions(state, action) {
      state.allRegions = action.payload;
    },
  },
});

export const myShopActions = mySlice.actions;

export default mySlice.reducer;
