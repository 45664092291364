import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";
import { getNameByCode } from "../../utils/ConvertCodeToName";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getEbmImportAction,
  updateEbmImportItemAction,
} from "../../store/ImportManagement/action";

dayjs.extend(customParseFormat);



const RegisterImport = (props: any) => {
  const { auth, ebmGeneral, ebmPurchase } = useSelector((state: any) => state);
  const [itemCode, setItemCode] = useState("");
  const [itemClassCode, setItemClassCode] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      itemSeq: props?.selectedData?.itemSeq,
      origin:
        ebmGeneral?.selectOption &&
        getNameByCode(
          ebmGeneral?.selectOption?.Cuntry,
          props?.selectedData?.orgnNatCd
        ),
      pkg: props?.selectedData?.pkg,
      qty: props?.selectedData?.qty,
      unit: props?.selectedData?.qtyUnitCd,
      itemName: props?.selectedData?.itemNm,
      totWt: props?.selectedData?.totWt,
      invcFcurAmt: props?.selectedData?.invcFcurAmt,
      hsCode: props?.selectedData?.hsCd,
      declarationDate: props?.selectedData?.dclDe,
    });
  }, [props?.selectedData]);
  const ApproveEbmInportItem = async (values?: any) => {
    auth?.token &&
      (await updateEbmImportItemAction(auth?.token, {
        ...values,
        taskCode: props?.selectedData?.taskCd,
        declarationDate: props?.selectedData?.dclDe,
        hsCode: props?.selectedData?.hsCd,
        itemClassCode: itemClassCode,
        itemCode: itemCode,
        itemSeq: props?.selectedData?.itemSeq,
        importItemStatusCode: "3",
      })(dispatch));
    auth?.token && (await getEbmImportAction(auth?.token, "")(dispatch));
    props?.onClose();
  };
  const cancelEbmImportItem = async (values?: any) => {
    auth?.token &&
      (await updateEbmImportItemAction(auth?.token, {
        ...values,
        taskCode: props?.selectedData?.taskCd,
        declarationDate: props?.selectedData?.dclDe,
        hsCode: props?.selectedData?.hsCd,
        itemClassCode: itemClassCode,
        itemCode: itemCode,
        importItemStatusCode: "4",
        itemSeq: props?.selectedData?.itemSeq,
      })(dispatch));
    auth?.token && getEbmImportAction(auth?.token, "")(dispatch);
    props?.onClose();
  };
  const onFinish = async (values?: any) => {
    auth?.token &&
      (await updateEbmImportItemAction(auth?.token, {
        ...values,
        importItemStatusCode: props?.selectedData?.imptItemsttsCd,
      })(dispatch));
    auth?.token && (await getEbmImportAction(auth?.token, "")(dispatch));
    props?.onClose();
  };
  const ItemData = ebmGeneral?.allItems?.map((el: any) => ({
    value: el?.itemCd,
    label: el?.itemCd,
  }));
  const ItemClassData = ebmGeneral?.allItems?.map((el: any) => ({
    value: el?.itemClsCd,
    label: el?.itemClsCd,
  }));
  console.log("ItemData", props?.selectedData?.imptItemsttsCd);
  return (
    <div>
      {props?.selectedData?.imptItemsttsCd && (
        <div className="flex justify-between">
          <div className="bg-[#F4F6FA] flex justify-center items-center gap-2 px-[.25rem] py-[.25rem] rounded-md">
            {" "}
            <h1 className="text-[.9rem] text-black pl-2">Current status:</h1>
            <CustomButton
              title={
                ebmGeneral?.selectOption &&
                getNameByCode(
                  ebmGeneral?.selectOption["Import Item Status"],
                  props?.selectedData?.imptItemsttsCd
                )
              }
              textColor="white"
              bgColor="green-500"
              textSize="base"
            />
          </div>
          {props?.selectedData?.imptItemsttsCd === "2" && (
            <div className="flex gap-5">
              <CustomButton
                title={
                  ebmPurchase?.isFetching ? <LoadingOutlined /> : "Approve"
                }
                textColor="[#0FA91E]"
                bgColor="[#0FA91E0D]"
                textSize="base"
                onClick={() => ApproveEbmInportItem()}
              />
              <CustomButton
                title={ebmPurchase?.isFetching ? <LoadingOutlined /> : "Cancel"}
                textColor="[#F66659]"
                bgColor="[#F6665980]"
                textSize="base"
                onClick={() => cancelEbmImportItem()}
              />
            </div>
          )}
        </div>
      )}
      <Form
        name="wrap"
        onFinish={onFinish}
        form={form}
        initialValues={props?.selectedData}
        colon={false}
        style={{ width: "100%" }}
      >
        <div className="flex gap-2 w-full pt-5">
          <div className="w-full">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[3.8rem]">
                Op.code:
              </span>
              name="opcd"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[2.7rem]">
                Decl.code:
              </span>
              name="declarationDate"
              rules={[{ required: true }]}
            >
              <Input className="h-10  w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[4.9rem]">
                Seq:
              </span>
              name="itemSeq"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[3.3rem]">
                HSCODE:
              </span>
              name="hsCode"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[2.4rem]">
                Item Code:
              </span>
              name="itemCode"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "80%", height: 40 }}
                placeholder={ebmGeneral?.isFetching ? "Lodding" : "Item Code"}
                optionFilterProp="children"
                onChange={(value: any) => setItemCode(value)}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input.toString())
                }
                options={ebmGeneral?.isFetching ? [] : ItemData}
              />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Item Class Code:
              </span>
              name="itemClassCode"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "80%", height: 40 }}
                placeholder={
                  ebmGeneral?.isFetching ? "Lodding" : "Item Class Code"
                }
                optionFilterProp="children"
                onChange={(value: any) => setItemClassCode(value)}
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input.toString())
                }
                options={ebmGeneral?.isFetching ? [] : ItemClassData}
              />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[4.5rem]">
                Origin:
              </span>
              name="origin"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[3.7rem]">
                PKG Qty:
              </span>
              name="pkg"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[5.2rem]">
                Qty:
              </span>
              name="qty"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[5rem]">
                Unit:
              </span>
              name="unit"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
          </div>
          <div className="w-full">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[2.9rem]">
                Item Desc:
              </span>
              name="desc"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[3.6rem]">
                Supplier:
              </span>
              name="supplier"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[4.4rem]">
                Agent:
              </span>
              name="agent"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[.4rem]">
                Tax Payer Name:
              </span>
              name="tax"
            >
              <Input className="h-10 w-[19rem] " />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[2.3rem]">
                Item Name:
              </span>
              name="itemName"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[3rem]">
                Gross WT:
              </span>
              name="totWt"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[1.9rem]">
                Invoice AMT:
              </span>
              name="invcFcurAmt"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Invoice Currency:
              </span>
              name="invoice"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>

            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2 mr-[4.5rem]">
                Rate:
              </span>
              name="rate"
            >
              <Input className="h-10 w-[19rem]" />
            </Form.Item>
          </div>
        </div>

        <Form.Item label=" " className="w-1/2 m-auto mt-5">
          <CustomButton
            title={ebmPurchase?.isFetching ? <LoadingOutlined /> : "Save"}
            bgColor="[#605BFF]"
            width="full"
            textColor="white"
            icon={<SaveIcon />}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterImport;
