import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DatePicker,
  DatePickerProps,
  Modal,
  Table,
  MenuProps,
  Space,
  Dropdown,
  Badge,
} from "antd";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { Divider } from "@mui/material";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import FilterButton from "./components/FilterButton";
import {
  deleteOneAccountAction,
  getAllAccountAction,
} from "../../../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import UpdateAccountForm from "../../../../components/forms/UpdateAccountForm";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import SubAccountFilter from "../../../../components/filters/subAccountFilter";
import { TbGridDots } from "react-icons/tb";
import { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../../utils/setColor";
import { myAccounts } from "../../../../store/account";
import { mySubscritionActions } from "../../../../store/subscription/order";
import { useParams } from "react-router-dom";
import { handleClearAllState } from "../../../../utils/converter";
import { myInvoices } from "../../../../store/invoice";
import { handlerSelectTabs } from "../../../../store/layout/actions";
import { accountIncludeItem } from "../../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { B2C_Account_Headers } from "../../../../components/csvHeaders/Headers";
import { getAllB2CAccount_CSVAction } from "../../../../store/csvDownload/actions";
import { ReactComponent as PeopleSvg } from "../../../../assets/icons/people.svg";
import { ReactComponent as NoteSvg } from "../../../../assets/icons/stickynote.svg";
import { ReactComponent as EditSvg } from "../../../../assets/edit.svg";
import { ReactComponent as TrashSvg } from "../../../../assets/trash.svg";
import { PiDotsThreeLight } from "react-icons/pi";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/deleteicon.svg";
import { ReactComponent as EditeSvg } from "../../../../assets/icons/editicon.svg";
import { getAllChannelAction } from "../../../../store/channel/actions";
import defaultPic from "../../../../assets/nophoto.png";

const DetailsPage = (props: any) => {
  const navigate = useNavigate();
  const { auth, account, layout, CSV } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const location = useLocation();
  const route = location.pathname;
  const path = route.includes("customers");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState<any>("");
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedChannel, setSelectedChannel] = useState<any>("");
  const [selectedType, setSelectedType] = useState<any>("");
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };
  const clearActions = [mySubscritionActions.setSelected, myInvoices?.setAll];

  useEffect(() => {
    if (auth?.token && !path) {
      getAllAccountAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}${selectedStatus ? `&status=${selectedStatus}` : ""
        }${selectedChannel ? `&channel=${selectedChannel}` : ""}${selectedType ? `&type=${selectedType}` : ""
        }&field=${accountIncludeItem}${layout?.searchResult !== "" && layout?.searchFrom === "account"
          ? `&sk=${layout?.searchResult}`
          : ""
        }`
      )(dispatch);
    }
  }, [auth?.token, dispatch, selectedType, selectedChannel, limit, page, selectedStatus, layout?.searchResult, layout?.searchFrom, path]);

  useEffect(() => {
    if (!accountId) {
      dispatch(myAccounts.setSelected(null));
      dispatch(mySubscritionActions.setSelected(null));
    }
  }, [accountId]);

  const openUpdateModel = (value: any) => {
    setUpdateData(value);
    setIsUpdateModalOpen(true);
  };

  const onCancelUpdateModel = () => {
    setUpdateData("");
    setIsUpdateModalOpen(false);
  };

  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };

  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token]);

  const deleteaccount = async () => {
    await deleteOneAccountAction(auth?.token, deleteDataId, {
      isDeleted: true,
    })(dispatch);
    onCancelDeleteModel();
  };
  const [activeAccount, setActiveAccount] = useState("accounts as grid");

  interface DataType {
    key: React.Key;
    name: string;
    accountid: string;
    type: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "accountid",
      title: "Account Id",
      dataIndex: "accountid",
      render: (text) => {
        return <p className="text-[#605BFF]">{text}</p>;
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "tel",
      title: "  Telephone",
      dataIndex: "tel",
    },
    {
      key: "device",
      title: "devices",
      dataIndex: "device",
    },
    {
      key: "status",
      title: "status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <button
              style={{ background: searchValue(status) }}
              className={`h-[1.5rem] w-[4rem] rounded-md `}
            >
              <p className="font-normal text-md text-white">{status}</p>
            </button>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "disable-navigation",
      render: (_, record: any) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <label
                onClick={() => openUpdateModel(record?.data)}
                className="flex gap-3 text-[.83rem]"
              >
                <EditeSvg /> Edit
              </label>
            ),
          },
          {
            key: "2",
            label: (
              <label
                onClick={(e) => openDeleteModel(record?._id)}
                className="flex gap-3 text-[.83rem]"
              >
                <DeleteSvg /> Delete
              </label>
            ),
          },
        ];
        return (
          <>
            <div className="flex gap-2">
              <Space direction="vertical">
                <Dropdown menu={{ items }} placement="bottom">
                  <PiDotsThreeLight size={30} />
                </Dropdown>
              </Space>
            </div>
          </>
        );
      },
    },
  ];
  const formData = account?.all?.data?.map((el: any) => {
    return {
      key: el._id,
      name: el?.customerDetails?.name,
      accountid: el?.accountID,
      type: el?.type?.toUpperCase(),
      tel: el?.customerDetails?.tel,
      device: el?.deliveryNote?.list?.length,
      date: el?.createdAt?.slice(0, 10),
      time: el?.createdAt?.substring(11, 16),
      status: el?.status,
      data: el,
      action: {
        openUpdateModel,
        openDeleteModel,
      },
    };
  });
  const handleIconClick = (iconType: string) => {
    setActiveAccount(iconType);
  };

  useEffect(() => {
    handleClearAllState(dispatch, clearActions);
    handlerSelectTabs(0)(dispatch);
  }, []);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getAllB2CAccount_CSVAction(
        auth?.token,
        `?page=0&limit=${account?.all?.total}${selectedStatus ? `&status=${selectedStatus}` : ""
        }${selectedStatus ? `&channel=${selectedStatus}` : ""}${selectedType ? `&type=${selectedType}` : ""
        }&field=${accountIncludeItem}`
      )(dispatch);
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map(
    (item: any, index: number) => {
      return {
        no: index + 1,
        date: item?.createdAt?.slice(0, 10),
        name: item?.customerDetails?.name,
        type: item?.type,
        tel: item?.customerDetails?.tel,
        status: item?.status,
      };
    }
  );
  return (
    <>
      {!path && (
        <div className="my-3 text-[#030229] opacity-70 flex justify-between mt-5">
          <DetailsHeaderActionBar pageName="Subscription" title="Accounts" />
          <DatePicker onChange={onChange} className={"w-48"} />
        </div>
      )}
      <div className=" h-full w-full bg-white ">
      {!path &&      <><div className="flex flex-row px-4 justify-between">
          <div className="flex gap-2">
            <SubAccountFilter
              setSelectedStatus={setSelectedStatus}
              setSelectedChannel={setSelectedChannel} />
            <div className="mt-2">
              <FilterButton setSelectedType={setSelectedType} />
            </div>
          </div>
          <div className="flex flex-row items-center gap-4">
            <div className="text-gray-500 flex flex-row px-4">
              <div
                style={{
                  color: activeAccount === "accounts as grid" ? "#3b82f6" : "gray",
                  cursor: "pointer",
                }}
              >
                <TbGridDots
                  size={30}
                  onClick={() => handleIconClick("accounts as grid")} />
              </div>

              <AiOutlineUnorderedList
                size={30}
                onClick={() => handleIconClick("accounts as table")}
                className={`cursor-pointer ${activeAccount === "accounts as table"
                  ? "fill-blue-500"
                  : "fill-gray-500"}`} />
              {layout?.selectedAccountType === 2 && (
                <CSVExportButton
                  csvHeaders={B2C_Account_Headers}
                  csvData={csvData}
                  filename={`B2C user_files.csv`} />
              )}
            </div>
          </div>
        </div><Divider sx={{ mt: 1, mb: 1 }} /></>}
        {activeAccount === "accounts as grid" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={account?.all?.total}
            count={Math.ceil(account?.all?.total / limit)}
          >
            <div
              className={`w-full h-full flex flex-wrap gap-2 ${layout?.isSideNavOpen ? "gap-4" : "gap-8"
                } gap-y-8 text-[#030229]`}
            >
              {account?.isFetching ? (
                <div className="flex flex-wrap">
                  {Array.from({ length: !path ? 8 : 4 })?.map((el: any) => (
                    <CurstomeCardSkeleton />
                  ))}
                </div>
              ) : (
                <>
                  {account?.all?.data?.map((items: any) => (
                    <Badge.Ribbon
                      key={items?._id}
                      text={
                        <span
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            fontWeight: 400,
                            paddingTop: "1.5px",
                          }}
                        >
                          {items?.status}
                        </span>
                      }
                      color={searchValue(items?.status)}
                      style={{ width: "5rem", padding: "3px 1px" }}
                    >
                      <div className="w-full xl:w-[386px] h-[182px] bg-white rounded-[10px] border-[.1px] shadow-[1px_4px_24px_0_rgba(192,192,192,0.1)] p-3 px-4 relative">
                        <div
                          onClick={(event: any) => {
                            if (path) {
                              // If path condition is true, do nothing
                              return;
                            }
                            const isDisabledDiv = event.target.closest(
                              ".ant-dropdown-trigger"
                            );
                            const isDisabledlabel =
                              event.target.closest(".ant-dropdown-menu");
                            if (!isDisabledDiv && !isDisabledlabel) {
                              navigate(`/subscription/account/${items?._id}`);
                            }
                          }}
                        >
                          {/* Image Section */}
                          <div className="flex gap-3">
                            <div>
                              {items?.productPlan ? (
                                <img
                                  src={
                                    items?.productPlan?.pricelistItem?.channel
                                      ?.logo
                                  }
                                  className="w-16 h-14 rounded"
                                  alt="Product Plan"
                                />
                              ) : (
                                <img
                                  src={defaultPic}
                                  className="w-16 h-14 rounded object-cover"
                                  alt="Default"
                                />
                              )}
                            </div>
                            <div className="flex flex-col gap-2">
                              <h1 className="text-[14px] font-normal w-[14rem]">
                                {items?.customerDetails?.name?.toUpperCase()}
                              </h1>
                              <p className="text-[#605BFF] text-[12px] font-normal">
                                {items?.accountID}
                              </p>
                            </div>
                          </div>

                          {/* Date and Devices Section */}
                          <div className="flex justify-between my-3 mt-4 font-medium">
                            <p className="text-[13px] font-normal">
                              {items?.createdAt?.slice(0, 10)}
                            </p>
                            <p className="text-[13px] font-normal">
                              {items?.deliveryNote?.list?.length || 0} Devices
                            </p>
                          </div>

                          <Divider />

                          {/* Additional Info Section */}
                          <div className="flex justify-between items-center mt-5 font-medium">
                            <div className="flex gap-3">
                              <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center">
                                <Space>
                                  <PeopleSvg fontSize={15} />
                                  {items?.customer?.otherPhone?.length || 0}
                                </Space>
                              </div>
                              <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center">
                                <Space>
                                  <NoteSvg fontSize={15} />
                                  {items?.notes?.length || 0}
                                </Space>
                              </div>
                              <div
                                className={`w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center uppercase ${items?.type === "b2b"
                                  ? "bg-blue-500 text-white"
                                  : items?.type === "b2c"
                                    ? "bg-green-500 text-white"
                                    : "bg-gray-200 text-black"
                                  }`}
                              >
                                {items?.type || 0}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Action Buttons */}
                        {!path && (
                          <div className="flex gap-2 absolute right-4 bottom-2">
                            <EditSvg onClick={() => openUpdateModel(items)} />
                            <TrashSvg
                              onClick={() => openDeleteModel(items?._id)}
                            />
                          </div>
                        )}
                      </div>
                    </Badge.Ribbon>
                  ))}
                </>
              )}
            </div>
          </ScrollableFrame>
        )}
        {activeAccount === "accounts as table" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={account?.all?.total}
            count={Math.ceil(account?.all?.total / limit)}
          >
            <Table
              onRow={(record: any, _rowIndex: any) => {
                return {
                  onClick: (event: any) => {
                    const isDisabledColumn = event.target.closest(
                      ".disable-navigation"
                    );
                    const isDisabledlabel =
                      event.target.closest(".ant-dropdown-menu");
                    if (!isDisabledColumn && !isDisabledlabel) {
                      navigate(`/subscription/account/${record?.key}`);
                    }
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={account?.isFetching}
            />
          </ScrollableFrame>
        )}
      </div>

      <Modal
        style={{ maxWidth: "100%" }}
        title="Update Account"
        open={isUpdateModalOpen}
        onCancel={onCancelUpdateModel}
        footer={null}
      >
        <UpdateAccountForm
          dataToUpdate={updateData}
          onCancel={onCancelUpdateModel}
        />
      </Modal>

      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteaccount}
        onCancel={onCancelDeleteModel}
        itemName={"Account"}
        isLoading={account?.isFetching}
      />
    </>
  );
};
export default DetailsPage;
