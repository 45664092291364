import React, { useState } from "react";
import { Select, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";

interface DataType {
  key: any;
  shop: any;
  received: any;
  sold: any;
  inshop: any;
}
interface DataTypes {
  key: any;
  warehouse: any;
  warehousein: any;
  warehouseout: any;
  totalwarehouse: any;
}
interface DataTypee {
  key: any;
  shop: any;
  device: any;
  quantity: any;
  issues: any;
}

export const ShopStock = (props: any) => {
  const [isRegionVisible, setIsRegionVisible] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "Shops",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Total Transfer",
      dataIndex: "transfer",
      key: "transfer",
    },
    {
      title: "Total Idle",
      dataIndex: "idle",
      key: "idle",
    },
    {
      title: "Total pending",
      dataIndex: "pending",
      key: "pending",
    },
  ];

  const handleChange = (value: string) => {
    props?.onRegionChange(value);
  };

  const handleChannelChnage = (value: any) => {
    props?.onChannelChnage(value);
    setIsRegionVisible(true);
  };

  const tableData = props?.overView?.liveStockAtShop?.data.map(
    (livestock: any) => {
      const { shop, statuses } = livestock;

      return {
        key: shop?._id,
        shop: shop?.name,
        region: shop?.region,
        transfer:
          statuses?.find((status: any) => status.status === "transfer")
            ?.count || 0,
        sold:
          statuses?.find((status: any) => status.status === "sold")?.count || 0,
        idle:
          statuses?.find((status: any) => status.status === "idle")?.count || 0,
        pending:
          statuses?.find((status: any) => status.status === "pending")?.count ||
          0,
      };
    }
  );

  return (
    <div className="w-full bg-[#fff] rounded-md h-[50vh] overflow-y-auto p-8">
      <div className="text-[#030229] text-lg flex justify-between">
        <div>
          <Space>
            <p className="text-[#030229] text-lg mr-4">Live Stock At Shop</p>
          </Space>
        </div>
        <div className="flex flex-row gap-3">
          <Select
            showSearch
            placeholder="Select a region"
            style={{ width: 200 }}
            onChange={handleChange}
            filterOption={(input: any, option: any) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            options={(props?.regions || []).map((region: any) => ({
              value: region?._id,
              label: region?.name,
            }))}
          />
          {/* <Select
            showSearch
            placeholder="Select By channel"
            style={{ width: 200 }}
            onChange={handleChannelChnage}
            filterOption={(input: any, option: any) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            options={(props?.channel?.all?.data || []).map((channel: any) => ({
              value: channel?._id,
              label: channel?.name,
            }))}
          /> */}
          {/* {isRegionVisible && (
            <Select
              showSearch
              placeholder="Select a region"
              style={{ width: 200 }}
              onChange={handleChange}
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={(props?.regions || []).map((region: any) => ({
                value: region?._id,
                label: region?.name,
              }))}
            />
          )} */}
        </div>
      </div>
      <div className="flex gap-10 my-3 text-sm">
        <p className="text-[#03022980] font-light text-base pt-3">
          SC:Service Center
        </p>

        <p className="text-[#03022980] font-light text-base pt-3">
          CS:Connect Shop
        </p>
      </div>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={props?.overView?.isFetching}
      />
    </div>
  );
};

// export const DefaultDevices = () => {
//   const columns: ColumnsType<DataTypee> = [
//     {
//       title: "Shops",
//       dataIndex: "shop",
//       key: "shop",
//     },
//     {
//       title: "Device Modal",
//       dataIndex: "device",
//       key: "device",
//     },
//     {
//       title: "Quantity",
//       dataIndex: "quantity",
//       key: "quantity",
//     },
//     {
//       title: "Default Issues",
//       dataIndex: "issues",
//       key: "issues",
//     },
//   ];
//   const data: DataTypee[] = [
//     {
//       key: "1",
//       shop: "Nyamirambo_CS",
//       device: "Galaxy S24",
//       quantity: 3,
//       issues: "Screen issue",
//     },
//     {
//       key: "2",
//       shop: "Nyabugogo_CS",
//       device: "Iphone 14",
//       quantity: 1,
//       issues: "Screen issue",
//     },
//     {
//       key: "3",
//       shop: "Huye_CS",
//       device: "Galaxy S24",
//       quantity: 6,
//       issues: "Network issue",
//     },
//   ];
//   const handleChange = (value: string) => {
//     console.log(`selected ${value}`);
//   };
//   return (
//     <div className="w-full bg-[#fff] rounded-md h-[50vh] overflow-y-auto p-8">
//       <div className="text-[#030229] text-lg flex justify-between">
//         <div>
//           <p className="text-[#030229] text-lg mr-4">Default Devices</p>
//         </div>
//         <Select
//           defaultValue="By Region"
//           style={{ width: 120 }}
//           onChange={handleChange}
//           options={[{ value: "shop", label: "Shop" }]}
//         />
//       </div>
//       <div className="flex gap-10 my-3 text-sm">
//         <p className="text-[#03022980] font-light text-base pt-3">
//           SC:Service Center
//         </p>

//         <p className="text-[#03022980] font-light text-base pt-3">
//           CS:Connect Shop
//         </p>
//       </div>
//       <Table columns={columns} dataSource={data} pagination={false} />
//     </div>
//   );
// };

export const WarehouseStock = (props: any) => {
  const columns: ColumnsType<DataTypes> = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse",
      key: "warehouse",
    },
    {
      title: "Warehouse In",
      dataIndex: "in",
      key: "in",
    },
    {
      title: "Warehouse Out",
      dataIndex: "out",
      key: "out",
    },
    {
      title: "Sold",
      dataIndex: "sold",
      key: "sold",
    },
  ];
  const { Text } = Typography;

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const tablewarehouse = props?.overView?.liveStockAtWarehouse?.data?.map(
    (item: any, index: any) => {
      return {
        key: index,
        brand: item?.name,
        sold: item?.status?.sold,
        out: item?.status?.out,
        idle: item?.status?.idle,
        in: item?.status?.in,
      };
    }
  );

  return (
    <div className="w-full bg-[#fff] rounded-md h-[50vh] overflow-y-auto p-8">
      <div className="text-[#030229] text-lg flex justify-between">
        <div>
          <p className="text-[#030229] text-lg mr-4">Live Stock At Warehouse</p>
        </div>
      </div>
      <div className="flex gap-10 my-3 text-sm">
        <p className="text-[#03022980] font-light text-base pt-3">
          SC:Service Center
        </p>

        <p className="text-[#03022980] font-light text-base pt-3">
          CS:Connect Shop
        </p>
      </div>
      <Table columns={columns} dataSource={tablewarehouse} />
    </div>
  );
};
