import { mySubscritionActions } from ".";
import {
  getAllNoSaleService,
  getAllPaymentHistoryService,
  getLiveStockAtShopService,
  getRevenueQuantityService,
  getTotalRevenueService,
  getLiveStockAtWarehouseService,
  getDevicesFinancedUnderChannelsService,
  getDevicesFinancedUnderBusinessProductsService,
  getRecentPaymentService,
  getStatsPlanService
} from "./services";

export const getAllPaymentHistoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllPaymentHistoryService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setAllPaymentHistory(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllNoSaleAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getAllNoSaleService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setNoSale(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getTotalRevenueAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getTotalRevenueService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setTotalRevenue(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getRevenueQuantityAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getRevenueQuantityService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setQuantityRevenue(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};



export const getRecentPaymentAction = (token: string, query?: string) => {
  // some logic
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getRecentPaymentService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setRecentPayment(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};






export const getLiveStockAtShopAction = (token: string,query?:any) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getLiveStockAtShopService(token,query);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setLiveStockAtShop(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};





export const getLiveStockAtWarehouseAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getLiveStockAtWarehouseService(token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setLiveStockAtWarehouse(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};




export const getDevicesFinancedUnderChannelsAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getDevicesFinancedUnderChannelsService(token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setDevicesFinancedUnderChannels(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};



export const getDevicesFinancedUnderBusinessProductsAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getDevicesFinancedUnderBusinessProductsService(token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setDevicesFinancedUnderBusinessProducts(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};



export const getStatsPlanServiceAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscritionActions.setIsFetching(true));
      const res = await getStatsPlanService(token);
      if (res?.status === 200) {
        dispatch(mySubscritionActions.setStatsPlan(res));
        dispatch(mySubscritionActions.setIsFetching(false));
      }
      dispatch(mySubscritionActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

