import React from "react";
import { Stack } from "@mui/material";
import { Divider, Tag } from "antd";
import { Table } from "antd";
import { Mycolumns } from "../../../../assets/data/data";
import { searchValue } from "../../../../utils/setColor";
import { useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

const Invoice = (props: any) => {
  const { company, order } = useSelector((state: any) => state);
  const [_page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);

  const productData = order?.selected?.list?.map((el: any) => {
    return {
      key: el._id,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      specs: el?.product?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
        ?.join(", "),
      quantity: el?.quantity,
      unitPrice: el?.amount,
      amountPaid: (Number(el?.amount) * Number(el?.quantity)).toLocaleString(),
    };
  });

  return (
    <div className="text-[#030229] h-screen px-3">
      <Stack
        direction="row"
        alignItems={"start"}
        justifyContent="space-between"
      >
        {" "}
        <div className="text-xl font-semibold">
          <h2>INVOICE</h2>{" "}
        </div>
        <Tag
          color={searchValue(props?.dataToDisplay?.data?.status)}
          className="capitalize p-2 px-3 text-sm"
        >
          {props?.dataToDisplay?.data?.status ?? props?.dataToDisplay?.status}
        </Tag>
        <div className="flex flex-col float-right mb-4 w-[16rem] text-[13px]">
          <div className="flex justify-between">
            <p className="font-medium text-[#000] ">Invoice Number</p>
            <p className="font-light  text-gray-500 ">
              {props?.dataToDisplay?.bill ?? props?.dataToDisplay?.transaction}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium text-[#000]">Invoice Date</p>
            <p className="font-light text-gray-500">
              {props?.dataToDisplay?.data?.date?.split("T")[0] ??
                props?.dataToDisplay?.date?.split("T")[0]}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="font-medium  text-[#000]">Due Date</p>
            <p className="font-light text-gray-500">
              {props?.dataToDisplay?.data?.dueDate?.split("T")[0] ??
                props?.dataToDisplay?.dueDate?.split("T")[0]}
            </p>
          </div>
        </div>
      </Stack>
      {/* <Divider /> */}
      <div className="flex justify-between pt-2">
        <div className="w-[17rem] ">
          <h2 className="text-base font-medium mb-4">BILLING INFORMATION</h2>
          <div className="flex flex-col gap-1">
            <p className="text-sm font-normal">
              {" "}
              {company?.selected?.name?.toUpperCase() || "Samphone"}
            </p>
            <p className="text-sm font-normal">
              {`${company?.selected?.address?.provence || ""} ${
                company?.selected?.address?.city || ""
              } ${company?.selected?.address?.country || ""}`}
            </p>
            <p className="text-sm font-normal">{company?.selected?.email}</p>
            <p className="text-sm font-normal">{company?.selected?.phone}</p>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="self-start inline-block min-w-[16rem] max-w-fit">
            <h2 className="text-base font-medium mb-4">SHIPPING INFORMATION</h2>
            <div className="flex flex-col gap-1">
              <p className="text-sm font-normal">
                {(props?.dataToDisplay?.data?.account?.type ||
                  props?.dataToDisplay?.account?.type) === "b2c"
                  ? "Individual"
                  : "Company"}{" "}
                :{" "}
                {props?.dataToDisplay?.data?.account?.customerDetails?.name?.toUpperCase() ??
                  props?.dataToDisplay?.account?.customerDetails?.name?.toUpperCase()}
              </p>
              <p className="text-sm font-normal">
                {props?.dataToDisplay?.data?.account?.customerDetails
                  ?.country ??
                  props?.dataToDisplay?.account?.customerDetails?.country}{" "}
                -{" "}
                {props?.dataToDisplay?.data?.account?.customerDetails
                  ?.district ??
                  props?.dataToDisplay?.account?.customerDetails?.district}
              </p>
              <p className="text-sm font-normal">
                {props?.dataToDisplay?.data?.account?.customerDetails?.email ??
                  props?.dataToDisplay?.account?.customerDetails?.email}
              </p>
              <p className="text-sm font-normal">
                {props?.dataToDisplay?.data?.account?.customerDetails?.tel ??
                  props?.dataToDisplay?.account?.customerDetails?.tel}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider dashed />

      <div className="text-base font-medium mb-1">
        <h2>Products</h2>{" "}
      </div>

      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={order?.selected?.list?.length}
        count={Math.ceil(order?.selected?.list?.length / limit)}
      >
        <div className=" w-full">
          <Table
            columns={Mycolumns}
            dataSource={productData}
            pagination={false}
            loading={order?.isFetching}
          />
        </div>
      </ScrollableFrame>
      <div className="flex flex-col mt-5 mb-2  float-right gap-2 w-[18rem] ">
        <div className="flex  justify-between">
          <p className="text-[.9rem] font-light">Amount Paid</p>
          <h2 className="text-[.9rem] font-medium ">
            {`${
              props?.dataToDisplay?.amountPaid?.toLocaleString() ?? 0
            } ${getDefaultCurrencyCode(company)}`}
          </h2>
        </div>
        <div className="flex justify-between">
          <p className="text-[.9rem] font-light">Taxes</p>
          <h2 className="text-[.9rem] font-medium ">0%</h2>
        </div>
        <div className="flex mt-2 justify-between ">
          <p className="text-[.9rem] font-medium">Total Amount To be paid</p>
          <h2 className="text-[.9rem] font-medium ">
            {`${
              props?.dataToDisplay?.amountTobePaid?.toLocaleString() ??0
            } ${getDefaultCurrencyCode(company)}`}
          </h2>
        </div>
      </div>
      <Divider dashed />
      <div className="flex gap-3 pb-3">
        <h2 className="text-base min-w-36">Additional Notes</h2>
        <p className="text-gray-500 text-sm">
          {props?.dataToDisplay?.data?.comments ?? "No Additional Notes"}
        </p>
      </div>
    </div>
  );
};
export default Invoice;
