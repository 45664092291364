import React, { useEffect, useState } from "react";
import { Radio, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { searchValue } from "../../utils/setColor";
import SubscriptionDrower from "../Modals/SubscriptionDrower";
import { getAllSubRequestedAction } from "../../store/subscription/subscriptionRequest/actions";
import { customerValidationAction } from "../../store/customer/actions";
import CSVExportButton from "../cards/NoSale/CSVExportButton";
import { Applications_Headers } from "../csvHeaders/Headers";
import { applicationreport_CSVAction } from "../../store/csvDownload/actions";
import ScrollableFrame from "../layout/ScrollableFrame";
import { myLayoutActions } from "../../store/layout";
import CustomModal from "../Modals/CustomModal";
import ConfirmSubscriptionPayment from "../forms/ConfirmSubscriptionPayment";
import ThreeSubDotDropdown from "../dropDown/ThreeSubDotDropdown";

const SubscriptionRequestTable = () => {
  const { auth, subscriptionRequest, layout, CSV } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModel] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState<any>(null);

  const handleCloseModel = () => {
    setOpenModel(false);
    setDataToUpdate(null);
  };

  const handleOpenModel = (data: any) => {
    setOpenModel(true);
    setDataToUpdate(data);
  };

  const showDrawer = (d: any) => {
    setOpen(true);
    setData(d);
    if (d?.data?.customer?._id) {
      customerValidationAction(
        d?.data?.customer?._id,
        {},
        auth?.token
      )(dispatch);
    }
  };

  const onClose = () => {
    if (
      !subscriptionRequest?.isFetching &&
      !subscriptionRequest?.isGeneratingInvoice
    ) {
      setOpen(false);
      setData("");
    }
  };
  const dataToDisplay = subscriptionRequest?.all?.data?.map((el: any) => {
    return {
      key: el._id,
      date: el?.createdAt?.slice(0, 10),
      applicationid: el?.cartId,
      nid: el?.customer?.subscriptionInfo?.nationalIDDoc,
      installmemtPlan: el?.instalmentPlan,
      installmentPayment:
        el?.instalmentPlan === "monthly"
          ? el?.selectedPlan?.monthlyInstalment
          : el?.instalmentPlan === "weekly"
          ? el?.selectedPlan?.weeklyInstalment
          : el?.selectedPlan?.dailyInstalment,
      shop: el?.shop?.name,
      channel: el?.shop?.channel?.name,
      agent: el?.createdBy?.names,
      agentPhone: el?.createdBy?.phone,
      name: el?.customer?.name,
      phone: el?.customer?.phone,
      imei: el?.list[0]?.warehouseProduct?.serialNumber,
      model: el?.selectedPlan?.pricelistItem?.product?.model,
      brand: el?.selectedPlan?.pricelistItem?.product?.brand,
      plan: el?.subscriptionPlan,
      subscriptionType: el?.businessProduct?.name ?? "N/A",
      specification:
        el?.list[0]?.shopStockItem?.requestedItem?.product?.specification
          ?.slice(2, 10)
          ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
          ?.join(", "),
      unpaidAmount: el?.selectedPlan?.initialPayment?.toLocaleString(),
      status: el?.status === "paid" ? "Done" : el?.status,
      currentStatus: el?.subscriptionStatus?.split("_")?.join(" "),
      subscriptionStatus: el?.subscriptionStatus,
      isAcceptedContract: el?.acceptTermsAndConditions,
      data: el,
    };
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text: any, record: any) => (
        <>
          <p className="capitalize">{text}</p>
          <p className="capitalize">{record?.channel}</p>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text: any, record: any) => (
        <>
          <p className="capitalize">{text}</p>
          <p className="capitalize">{record?.agentPhone}</p>
        </>
      ),
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <>
          <p className="capitalize">{text}</p>
          <p className="capitalize">{record?.phone}</p>
        </>
      ),
    },
    {
      title: "Application ID",
      dataIndex: "applicationid",
      key: "applicationid",
    },

    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text: any, record: any) => (
        <div>
          <p className="capitalize">{`${text}`}</p>
          {/* <p className="capitalize">{`${record?.specification}`}</p> */}
        </div>
      ),
    },

    {
      title: "Upfront",
      dataIndex: "unpaidAmount",
      key: "unpaidAmount",
    },
    {
      title: "Installment Payment",
      dataIndex: "installmentPayment",
      key: "installmentPayment",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text?.toLocaleString()} ${
          record?.installmemtPlan
        }`}</p>
      ),
    },
    {
      title: "Subscription Period",
      dataIndex: "plan",
      key: "plan",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text} Months`}</p>
      ),
    },
    {
      title: "Business Product Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
      render: (text: any, record: any) => (
        <p className="capitalize">{`${text}`}</p>
      ),
    },
    {
      title: "Current Status",
      dataIndex: "currentStatus",
      key: "currentStatus",
      render: (text: any, record: any) => (
        <button
          style={{ background: searchValue(text) }}
          className=" text-[#03022980] font-normal py-1.5 px-4 rounded-md capitalize min-w-24"
        >
          {text}
        </button>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => (
        <button
          style={{ background: searchValue(text) }}
          className=" text-[#03022980] font-normal py-1.5 px-4 rounded-md capitalize min-w-24"
        >
          {text}
        </button>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <>
          <ThreeSubDotDropdown
            onOpenViewModel={() => showDrawer(record)}
            onOpenConfrimPaymentModel={() => handleOpenModel(record)}
            data={record}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (auth?.token) {
      getAllSubRequestedAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}${
          layout?.subReqStatus ? `&status=${layout?.subReqStatus}` : ""
        }`
      )(dispatch);
    }
  }, [auth?.token, dispatch, layout?.subReqStatus, page, limit]);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      applicationreport_CSVAction(
        auth?.token,
        `?limit=${subscriptionRequest?.all?.total}&page=0${
          layout?.subReqStatus ? `&status=${layout?.subReqStatus}` : ""
        }`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.createdAt?.slice(0, 10),
      applicationId: el?.cartId,
      channel: el?.shop?.channel?.name,
      shop: el?.shop?.name,
      agent: el?.createdBy?.names,
      agentPhone: el?.createdBy?.phone,
      customerName: el?.customer?.name,
      customerPhone: el?.customer?.phone,
      customerAddress: `${
        el?.customer?.subscriptionInfo?.address?.country || ""
      }-${el?.customer?.subscriptionInfo?.address?.province || ""}-${
        el?.customer?.subscriptionInfo?.address?.district || ""
      }-${el?.customer?.subscriptionInfo?.address?.sector || ""}-${
        el?.customer?.subscriptionInfo?.address?.cell || ""
      }-${el?.customer?.subscriptionInfo?.address?.village || ""}`,
      customerSourceIncome: el?.customer?.subscriptionInfo?.mainIncomeSource,
      customerOccupation:
        el?.customer?.subscriptionInfo?.occupationOrBusinessType,
      customerMonthlyIcome: el?.customer?.subscriptionInfo?.monthlyIncome,
      customerIcomeFrequency: el?.customer?.subscriptionInfo?.incomeFrequency,
      model: el?.selectedPlan?.pricelistItem?.product?.model,
      upfront: el?.selectedPlan?.initialPayment?.toLocaleString(),
      payment: el?.selectedPlan?.monthlyInstalment,
      period: `${el?.subscriptionPlan} Months`,
      bProductType: el?.businessProduct?.name ?? "N/A",
      customerDocs: `${el?.customer?.subscriptionInfo?.nationalIDDoc} ${el?.customer?.subscriptionInfo?.photoCustomerHoldingID}`,
      imei: el?.list[0]?.warehouseProduct?.serialNumber,
      status: el?.status === "paid" ? "Done" : el?.status,
      currentStatus: el?.subscriptionStatus?.split("_")?.join(" "),
      data: el,
    };
  });

  const scroll = { x: true as const };

  const options = [
    { label: "All", value: "" },
    { label: "Requested", value: `requested` },
  ];

  const handleChangeCartStatus = ({ target: { value } }: any) => {
    dispatch(myLayoutActions.setSubReqStatus(value));
  };

  return (
    <>
      <div className="bg-white mb-6 py-2.5 px-2 rounded-md flex items-center justify-between">
        <Radio.Group
          options={options}
          onChange={handleChangeCartStatus}
          value={layout?.subReqStatus}
          optionType="button"
          buttonStyle="solid"
          // defaultValue={"requested"}
        />
        <CSVExportButton
          csvHeaders={Applications_Headers}
          csvData={csvData}
          filename={`applications_report.csv`}
        />
      </div>
      <div className="bg-white h-[50rem] overflow-y-auto">
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={subscriptionRequest?.all?.total}
          count={Math.ceil(subscriptionRequest?.all?.total / limit)}
        >
          <Table
            columns={columns}
            dataSource={dataToDisplay}
            scroll={scroll}
            pagination={false}
            loading={subscriptionRequest?.isFetching}
            className="scrollbar-hide"
          />
        </ScrollableFrame>
      </div>
      <SubscriptionDrower open={open} onClose={onClose} data={data} />
      <CustomModal
        openModal={openModal}
        cancelModal={handleCloseModel}
        component={
          <div className="py-5">
            <ConfirmSubscriptionPayment
              dataToUpdate={dataToUpdate}
              onClose={handleCloseModel}
            />
          </div>
        }
        width={600}
      />
    </>
  );
};
export default SubscriptionRequestTable;
