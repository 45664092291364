import { notification } from "antd";
import { mySubscriptionRequestActions } from ".";
import {
  generateFirstInvoiceService,
  getAllSubRequestService,
  updateCartDetailsService,
} from "./services";

export const getAllSubRequestedAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscriptionRequestActions.setIsFetching(true));
      const res = await getAllSubRequestService(token, query);
      if (res?.status === 200) {
        dispatch(mySubscriptionRequestActions.setAll(res));
        dispatch(mySubscriptionRequestActions.setIsFetching(false));
      }
      dispatch(mySubscriptionRequestActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const acceptSubRequestAction = (
  token: string,
  cartId: string,
  data: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscriptionRequestActions.setIsFetching(true));
      const res = await updateCartDetailsService(token, cartId, data);
      if (res?.status === 200) {
        const resAll = await getAllSubRequestService(token, query);
        dispatch(mySubscriptionRequestActions.setAll(resAll));
        dispatch(mySubscriptionRequestActions.setUpdated(res?.data));
        dispatch(mySubscriptionRequestActions.setIsFetching(false));
        notification.success({ message: "Accepted successfully" });
        return true;
      }
      dispatch(mySubscriptionRequestActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSubRequestAction = (
  token: string,
  cartId: string,
  data: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscriptionRequestActions.setIsFetching(true));
      const res = await updateCartDetailsService(token, cartId, data);
      if (res?.status === 200) {
        const resAll = await getAllSubRequestService(token, query);
        dispatch(mySubscriptionRequestActions.setAll(resAll));
        dispatch(mySubscriptionRequestActions.setUpdated(res?.data));
        dispatch(mySubscriptionRequestActions.setIsFetching(false));
        return true;
      }
      dispatch(mySubscriptionRequestActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const generateB2CFirstInvoiceAction = (
  token: string,
  cartId: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(mySubscriptionRequestActions.setIsGeneratingInvoice(true));
      const res = await generateFirstInvoiceService(cartId, token);
      if (res?.status === 200) {
        dispatch(mySubscriptionRequestActions.setIsGeneratingInvoice(false));
        return true;
      }
      dispatch(mySubscriptionRequestActions.setIsGeneratingInvoice(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
