import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getAllSubRequestService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cart/request${query}`, token);
};

export const updateCartDetailsService = async (
  token: any,
  cartId: any,
  data: any
) => {
  return HttpRequest.update(`${SERVER_URL}/cart/one/${cartId}`, data, token);
};

export const generateFirstInvoiceService = async (
  cardId: string,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/invoice/cart/${cardId}`,
    cardId,
    token
  );
};
