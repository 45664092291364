import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel, Modal, Tag } from "antd";
import DeleteModal from "../../Modals/DeleteModal";
import { deletePlanAction } from "../../../store/channel/actions";
import defaultPic from "../../../assets/nophoto.png";

import AddItem from "../../../pages/dashboard/channels/subscription/AddItems";
import ViewSubscriptionForm from "../../forms/ViewSubscriptionForm";
import ThreeDotDropdown from "./threeDotDropdown";
import { useParams } from "react-router-dom";

const SubProductCardGrid = (props: any) => {
  const { auth, channel, layout } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const [editSubPrice, setEditSubPrice] = useState(false);
  const dispatch = useDispatch();
  const { subChannelId, priceId } = useParams();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleEditSubPrice = async () => {
    setEditSubPrice(true);
  };
  const handleCancelSubPrice = () => {
    setEditSubPrice(false);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    await deletePlanAction(
      auth?.token,
      props?.data._id,
      `?channel=${
        channel?.selected?._id ?? subChannelId
      }&page=0&limit=15&type=${layout?.subscriptionType}`
    )(dispatch);
  };

  return (
    <>
      <div
        className={`group shadow rounded-lg p-4 xmx-auto text-gray-900 bg-white relative ${
          layout.isSideNavOpen ? "w-[24rem]" : "w-[26.5rem]"
        } h-[24.5rem]`}
      >
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold mb-2 pl-1">
            {props?.data?.pricelistItem?.product?.model}
          </h2>
          {[
            "admin",
            "finance",
            "finance-manager",
            "dev",
            "b2b-relation",
          ].includes(auth?.user?.role?.toLowerCase()) && (
            <div>
              <ThreeDotDropdown
                onUpdate={handleEditSubPrice}
                onDelete={handleClickDelete}
              />
            </div>
          )}
        </div>
        <p className="text-sm font-normal text-gray-600 flex flex-wrap leading-4">
          {props?.data?.pricelistItem?.specification?.slice(0, 4)?.map(
            (data: any, _index: number) =>
              data[1] && (
                <div className="flex flex-row">
                  <p className="text-xs px-1">
                    <span className="font-semibold text-xs">{`${data[0]}: `}</span>
                    <span className="text-xs text-gray-600">
                      {` ${data[1]} |`}
                    </span>
                  </p>
                </div>
              )
          )}
        </p>
        <div className="flex justify-center mt-4 mb-6">
          {props?.data?.pricelistItem?.product?.images?.length === 0 ? (
            <div>
              <img src={defaultPic} className="w-32 h-32" alt="" />
            </div>
          ) : (
            <Carousel autoplay style={{ height: "128px", width: "128px" }}>
              {props?.data?.pricelistItem?.product?.images?.map((im: any) => (
                <div>
                  <img
                    src={im}
                    width={"100%"}
                    className=" h-36 object-cover"
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <div className="mt-6">
          <p className="text-center text-sm text-gray-600">
            <Tag className="py-0.5 mb-1">
              <span className="text-xs text-gray-600 mr-1">Upfront:</span>
              <span className="font-semibold text-xs">
                {`${props?.data?.initialPayment?.toLocaleString()} Rwf`}
              </span>
            </Tag>

            {props?.data?.monthlyInstalment && (
              <Tag className="py-0.5">
                <span className="font-semibold text-xs">
                  {`${props?.data?.monthlyInstalment?.toLocaleString()} Rwf`}
                </span>
                <span className="text-xs text-gray-600">/Month</span>
              </Tag>
            )}
          </p>
          <hr className="my-2" />
          <div className="flex justify-center">
            <div className="flex gap-3">
              <p>Bundle:</p>
              <Tag>{props?.data?.bundle?.[0] || 0}</Tag>
            </div>
            <div className="flex gap-3">
              <p>SMS:</p>
              <Tag>{props?.data?.sms?.[0] || 0}</Tag>
            </div>
            <div className="flex gap-3">
              <p>Calls</p>
              <Tag>{props?.data?.call?.[0] || 0}</Tag>
            </div>
          </div>
          <hr className="my-2" />
          <p className="text-center text-lg font-semibold mt-1">{`For ${props?.data?.duration} Months`}</p>
        </div>

        <div className="absolute bottom-1 right-0">
          <Tag className="bg-[#352794] mx-0 border-0 py-1 text-white capitalize rounded">
            {props?.data?.businessProductType?.toLowerCase()}
          </Tag>
        </div>
      </div>

      <Modal
        title={"Edit Plan Details"}
        open={editSubPrice}
        onCancel={handleCancelSubPrice}
        footer={null}
        width={700}
      >
        <AddItem
          dataToUpdate={props?.data}
          onCancel={handleCancelSubPrice}
          setSelectedModelId={priceId}
          selectePlanPlanType={"subscription"}
        />
      </Modal>

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={channel.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
      <Modal
        open={false}
        key={false ? "modal-open" : "modal-closed"}
        footer={null}
        width={1000}
      >
        <ViewSubscriptionForm data={props?.data} />
      </Modal>
    </>
  );
};

export default SubProductCardGrid;
