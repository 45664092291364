import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "reportLibrary",
  initialState: {
    isFetching: false,
    transactionIsFetching: false,
    all: { data: [] },
    saveTheChildren: null,
    selected: null,
    stockReport: null,
    transerReport: null,
    customerTransaction: null,
    searchResult: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSaveTheChildren(state, action) {
      state.saveTheChildren = action.payload;
    },
    setStockReport(state, action) {
      state.stockReport = action.payload;
    },
    setTranserReport(state, action) {
      state.transerReport = action.payload;
    },
    setCustomerTransaction(state, action) {
      state.customerTransaction = action.payload;
    },
    setTransactionIsFetching(state, action) {
      state.transactionIsFetching = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
  },
});

export const myReportActions = mySlice.actions;

export default mySlice.reducer;
