import { Stack, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  deleteTransferAction,
  getStatusCountAction,
  getTransfersTobeReturnedActions,
} from "../../../../store/wareHouseOut/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { myChannelActions } from "../../../../store/channel";
import { getAllStockSentAction } from "../../../../store/channel/actions";
import { Badge, Button, Divider, Popover } from "antd";
import { useLocation } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import React, { useState, useEffect } from "react";

const PageView = (props: any) => {
  const isChannel = props.route === "channel";
  const { wareHouseOut, auth, layout, channel } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current route
  const [searchKey, setSearchKey] = useState<string>();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const [status, SetStatus] = useState<string>("");

  useEffect(() => {
    auth?.token &&
      getAllStockSentAction(
        auth?.token,
        `?status=return&status=partial_Received`
      )(dispatch);
  }, [auth?.token, dispatch, status]);

  useEffect(() => {
    if (auth?.token) {
      getStatusCountAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  useEffect(() => {
    dispatch(myChannelActions.setRfsSelected(null));
  }, [dispatch]);

  const goBack = () => {
    navigate(-1);
  };

  const handleNavigation = (itemId: string) => {
    // Check if the current route matches /inventory/returnedstock
    if (location.pathname === "/inventory/returnedstock") {
      navigate(`/inventory/returnedstock/${itemId}`);
    }
  };

  const handleDelete = async (record: any) => {
    if (auth?.token) {
      await deleteTransferAction(auth.token, record._id)(dispatch);
      getAllStockSentAction(auth?.token, `?status=return&status=partial_Received`)(dispatch);
    }
  };

  const DeleteConfirmation = ({ item }: { item: any }) => (
    <Popover
      content={
        <div className="flex space-x-2">
          <Button
            type="primary"
            danger
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item);
            }}
          >
            Confirm
          </Button>
          <Button size="small" onClick={(e) => e.stopPropagation()}>
            Cancel
          </Button>
        </div>
      }
      title="Are you sure you want to cancel this transfer?"
      trigger="click"
    >
      <div
        className="text-red-500 text-center text-xs bg-white rounded-md border border-red-500 cursor-pointer w-[66px]"
        onClick={(e) => e.stopPropagation()}
      >
        Cancel
      </div>
    </Popover>
  );


  return (
    <Stack spacing={1}>
      <div className="text-black pt-5 pb-2 pl-2">
        <div className="text-black pt-5 pb-2 pl-2 flex justify-between">
          <DetailsHeaderActionBar
            title={"returned stock"}
            pageName={"inventory"}
            goBack={goBack}
          />
        </div>
      </div>

      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={wareHouseOut?.transfersTobeReturned?.total}
        count={Math.ceil(wareHouseOut?.transfersTobeReturned?.total / limit)}
      >
        <Stack spacing={1}>
          <Box>
            <div className="items-start mt-4">
              {channel?.isFetching ? (
                <Stack direction={"row"} spacing={2}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Box key={index} sx={{ width: 270 }}>
                      <Skeleton sx={{ height: 70, m: 0 }} />
                      <Stack direction={"row"} justifyContent="space-between">
                        <Skeleton animation="wave" sx={{ width: "40%" }} />
                        <Skeleton animation={false} sx={{ width: "30%" }} />
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              ) : (
                <div
                  className={`grid ${
                    layout?.isSideNavOpen
                      ? "sm:grid-cols-1 lg:grid-cols-2"
                      : "sm:grid-cols-2 lg:grid-cols-3"
                  } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
                >
                  {channel?.selectedRfs?.data.map((item: any) => (
                    <Badge.Ribbon
                      key={item._id}
                      text={<h1 className="capitalize px-2">{item?.status}</h1>}
                      color={"#a9a9a9"}
                    >
                      <div
                        className="h-[190px] bg-white p-2 rounded-md hover:border-black relative shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229]"
                        onClick={() => handleNavigation(item?._id)} // Call handleNavigation
                      >
                        <div className="py-2 px-1">
                          <div className="text-base space-y-2">
                            <h4 className="font-normal text-[14px] leading-3 text-[#030229] font-poppins">
                              {item?.requestId}
                            </h4>
                            <div className="flex flex-row justify-between w-full">
                              <p className="text-[13px] font-normal text-blue-400">
                                {`${item?.shopId?.name || "N/A"}`}
                              </p>
                              <DeleteConfirmation item={item} />
                            </div>
                          </div>
                          <Divider />
                          <div className="flex justify-between items-end w-full">
                            <div>
                              <p className="opacity-80 text-[12px] font-normal leading-normal">
                                Transferred By:{" "}
                                <span className="font-semibold text-xs pl-2">
                                  {item?.createdBy?.names}
                                </span>
                              </p>
                              <p className="opacity-80 text-[12px] font-normal leading-normal">
                                Created On:
                                <span className="font-semibold text-xs pl-2">
                                  {item?.createdAt.slice(0, 10)}
                                </span>
                              </p>
                            </div>
                            <p className="text-[14px] font-medium leading-normal pr-3">
                              {item?.list?.map(
                                (device: any) => device?.qtyRequested
                              )}{" "}
                              items
                            </p>
                          </div>
                        </div>
                      </div>
                    </Badge.Ribbon>
                  ))}
                </div>
              )}
            </div>
          </Box>
        </Stack>
      </ScrollableFrame>
    </Stack>
  );
};

export default PageView;
