import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const createIntegrationService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/settings/integration`, data, token);
};

export const updateIntegrationService = async (token: any,_id:any,data: any, ) => {
  return await HttpRequest.update(`${SERVER_URL}/settings/integration/one/${_id}`, data, token);
};




