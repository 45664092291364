import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";
import CustomerCard from "../../../components/cards/customerCard";
import ContactInfoCard from "../../../components/cards/ContactInfoCard";
import CreditReportCard from "../../../components/cards/CreditReportCar";
import OverViewCard from "../../../components/cards/OverviewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { Card, Tabs } from "antd";
import Index from "./Billings";
import { useDispatch, useSelector } from "react-redux";
import CustomerDevices from "./Devices/CustomerDevices";
import {
  getCustomerDevicesAction,
  getCustomerInvoiceAction,
  getCustomerMetricsAction,
  getCustomerNoSaleAction,
  getCustomerNotesAction,
} from "../../../store/customer/actions";
import { getAllAccountAction } from "../../../store/account/actions";
import { getOneCompanyAction } from "../../../store/setting/company/actions";
import File from "./files/File";
import CustomerRepairTable from "../../../components/tables/CustomerRepairTable";
import NotesCard from "../subscription/Accounts/components/NotesCard";
import CustomerNoSaleTable from "../../../components/tables/CustomerNoSaleTable";
import { COMPANY_PROFILE_ID } from "../../../utils/constants";
import SubscriptionPage from "../subscription/Accounts/SubscriptionPage";

const DetailsPage = (props: any) => {
  const { auth, customer } = useSelector((state: any) => state);
  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => {
    navigate(-1);
  };

  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };
  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerInvoiceAction(
        auth?.token,
        `${customer?.selected?._id}?limit=${limit}&page=${page}&status=${status}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch, page, limit, status]);

  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerNotesAction(
        auth?.token,
        `?customerId=${customer?.selected?._id}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch]);
  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerNoSaleAction(
        auth?.token,
        `?customerId=${customer?.selected?._id}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch]);

  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getAllAccountAction(
        auth?.token,
        `?customer=${customer?.selected?._id}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch]);

  useEffect(() => {
    if (customer?.selected?._id && auth?.token) {
      getCustomerDevicesAction(
        auth?.token,
        `?customerId=${customer?.selected?._id}`
      )(dispatch);
    }
  }, [auth?.token, customer?.selected?._id, dispatch]);

  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
  }, [auth.token, dispatch, companyId]);

  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerMetricsAction(customer?.selected?._id, auth.token)(dispatch);
  }, [auth.token, dispatch, customer?.selected?._id]);

  const tabsComponent = [
    {
      title: (
        <h1 className="text-base">
          Accounts
        </h1>
      ),
      components: <SubscriptionPage />,
    },
    {
      title: (
        <h1 className="text-base">
          {`Invoices & Billings ( ${customer?.customerInvoice?.total ?? 0}) `}
        </h1>
      ),
      components: (
        <div>
          <Index
            data={customer}
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            setStatus={setStatus}
          />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">
          Devices ({customer?.customerDevices?.total ?? "0"})
        </h1>
      ),
      components: <CustomerDevices />,
    },
    {
      title: <h1 className="text-base">Files</h1>,
      components: <File />,
    },
    {
      title: (
        <h1 className="text-base">
          Notes ({customer?.customerNotes?.total ?? 0})
        </h1>
      ),
      components: (
        <div>
          <NotesCard notes={customer?.customerNotes?.data} />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">
          Repairs ({customer?.customerRepairs?.total ?? 0})
        </h1>
      ),
      components: (
        <div>
          <CustomerRepairTable />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">{`No Sales (${customer?.customerNoSale?.total ?? 0
          })`}</h1>
      ),
      components: (
        <div>
          <CustomerNoSaleTable data={customer?.customerNoSale?.data} />
        </div>
      ),
    },
  ];
  const items = tabsComponent.map((el: any, i) => {
    const id = String(i + 1);
    return {
      label: el?.title,
      key: id,
      children: el?.components,
    };
  });

  return (
    <div className="text-black p-1 mt-3 space-y-2">
      <DetailsHeaderActionBar
        goBack={goBack}
        pageName={props?.pageName}
        title={props?.title}
      />
      <div className="xl:flex gap-5 mt-5">
        <div className="lg:flex gap-5 mt-5">
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 500 }}>
              <Card.Meta />
            </Card>
          ) : (
            <CustomerCard
              data={customer?.selected}
              onEdit={props?.handleClickEdit}
            />
          )}
          <div
            style={styles.customShadow}
            className="bg-white p-5 px-7 lg:w-[480px] w-full rounded-md mt-5 lg:mt-0"
          >
            <div className="flex justify-between">
              <h1 className="text-[#030229] text-base font-medium">
                {` Other Contact (${customer?.selected?.subscriptionInfo?.witnessInfo?.length})`}
              </h1>
              {/* <button className="text-[#605BFF] text-base">See All</button> */}
            </div>
            <div className="mt-3">
              <ContactInfoCard data={customer?.selected} />
            </div>
          </div>
        </div>
        <div className="mt-5 w-full">
          <CreditReportCard />
        </div>
      </div>
      <div className="mt-5 py-5">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Total Devices"
              total={
                customer?.customerMetrics?.totalDevices?.toLocaleString() ?? 0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Total Invoice amount"
              total={
                customer?.customerMetrics?.totalAmountPaid?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Total Bill Amount"
              total={
                customer?.customerMetrics?.totalPendingLateAmount?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="No Sales Amount"
              total={
                customer?.customerMetrics?.totalNoSaleAmount?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Next Payment Due"
              total={
                customer?.customerMetrics?.nextBillingDate?.slice(0, 10) ??
                "N/A"
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Upcoming Payment"
              total={
                customer?.customerMetrics?.nextBillingAmount?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
        </div>
      </div>
      {/* <div className="flex flex-row-reverse gap-2 pt-12">
        <SidePanelDetails
          handleClickDelete={props?.handleClickDelete}
          handleClickEdit={props?.handleClickEdit}
          simpleDetails={props?.simpleDetails}
        />
        <DetailsTabs
          tabs={props?.tabs}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      </div> */}

      <Tabs items={items} />
    </div>
  );
};

export default DetailsPage;
