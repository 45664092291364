import { Badge, Carousel, Tag } from "antd";
import React from "react";
import defaultPic from "../../../../../assets/nophoto.png";
import { borderColors } from "../../../../../utils/setColor";
import { useSelector } from "react-redux";

export const PlanCard: React.FC<{
  model: string;
  brand: string;
  price: number;
  upfront: number;
  duration: string;
  images: any;
  plan: string;
  specification: any;
  billingType: string;
  call: any;
  sms: any;
  bundle: any;
}> = ({
  model,
  brand,
  price,
  upfront,
  duration,
  images,
  specification,
  plan,
  billingType,
  call,
  sms,
  bundle,
}) => {
  return (
    <>
      <div className="mb-4 flex ">
        {images?.length > 0 ? (
          <div style={{ width: "6rem" }}>
            <Carousel autoplay arrows>
              {images?.map((el: any) => (
                <img
                  src={el}
                  alt={`${model}image`}
                  className="w-24 h-24 object-cover"
                />
              ))}
            </Carousel>
          </div>
        ) : (
          <img
            src={defaultPic}
            alt={`${model}image`}
            className="w-24 h-24 object-cover"
          />
        )}
        <div className="pl-2">
          <span className="text-sm text-gray-600">{brand}</span>
          <h2 className="text-base font-semibold text-gray-700">{model}</h2>
          <div className="flex">
            <div className="border-r pr-2">
              <p className="text-lg font-semibold text-gray-900 mt-2">
                {price?.toLocaleString()} Rwf
              </p>
              <p className="text-xs text-gray-500 capitalize">
                {billingType === "subscription" && "/"} {plan}
              </p>
            </div>
            <div className="pl-2">
              <p className="text-lg font-semibold text-gray-900 mt-2">
                {upfront?.toLocaleString()} Rwf
              </p>
              <p className="text-xs text-gray-500">Upfront</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div className="mt-4 text-sm text-gray-600 border-r xpr-2">
          {specification?.slice(2)?.map(
            (item: any) =>
              item[1] && (
                <div className="flex items-center gap-2">
                  <span className="font-bold text-xs">{item[0]}: </span>
                  <span className=" text-sm">{item[1]}</span>
                </div>
              )
          )}
        </div>
        <div className="flex flex-col gap-y-2 items-start pl-4">
          <div className="flex items-center gap-x-2">
            <p className="text-sm text-gray-600">{duration}</p>
            <p className="text-xs text-gray-500">Duration</p>
          </div>
          <div className="flex text-xs ">
            <Tag className="">{call}</Tag>

            <Tag className="">{sms}</Tag>

            <Tag className="">{bundle}</Tag>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <span className="text-sm mt-6 w-44 py-2 px-4  text-blue-500 border border-blue-500 rounded-full font-semibold text-center">
          Select Plan
        </span>
      </div>
    </>
  );
};

export const NetPlanCard = () => {
  const { subPriceList } = useSelector((state: any) => state);

  return (
    <div className="grid grid-cols-3 gap-3 pb-4">
      {subPriceList?.netPricelist?.data?.map((item: any, index: number) => (
        <Badge.Ribbon
          text={`${item?.finalPayment?.toLocaleString() ?? 0} Rwf`}
          className="h-10 pt-2 font-bold bg-green-400"
        >
          <div
            className="xmin-w-[20rem] bg-white rounded-lg shadow-md p-6 border-l-4"
            style={{
              borderColor: borderColors[index % borderColors.length],
            }}
          >
            <div className="text-gray-500 text-sm mb-2">
              {`${item?.duration} Duration`}{" "}
            </div>
            <div className="text-lg font-bold text-gray-800 mb-4">
              <span className="text-sm font-bold capitalize">Upfront: </span>
              {`${item?.initialPayment?.toLocaleString() ?? 0} Rwf`}{" "}
            </div>

            <ul className="text-gray-600 space-y-2">
              <li>➕ {`Bundles (${item?.bundle?.at(0)})`}</li>
              <li>➕ {`SMS (${item?.sms?.at(0)})`}</li>
              <li>➕ {`Talk Time (${item?.call?.at(0)})`}</li>
            </ul>
          </div>
        </Badge.Ribbon>
      ))}
    </div>
  );
};
