import { Stack, Skeleton } from "@mui/material";
import HeaderComponent from "../../../components/HeaderChannel";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AddItem from "../channels/priceList/AddItems";
import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "../../../components/grids/priceList/ProductGrid";
import { getAllProductsAction } from "../../../store/product/actions";
import {
  getPricelistItemAction,
  getPricelistAction,
  getPricelistSearchAction,
} from "../../../store/channel/actions";
import PriceListFilter from "../../../components/filters/PriceListFiltering";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import SearchInput from "../../../components/buttons/SearchButton";
import { getActivePrice } from "../../../utils/converter";
import { PriceListHeaders } from "../../../components/csvHeaders/Headers";
import { myProductActions } from "../../../store/product";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import FilesCard from "../../../components/cards/NoSale/FilesCard";
import { VscFilePdf } from "react-icons/vsc";
import { getAllPriceList_CSVAction } from "../../../store/csvDownload/actions";

const PageView = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = React.useState(" ");

  const handleCancel = () => {
    setIsModalOpen(false);
    getPricelistItemAction(
      auth?.token,
      `?page=${page - 1}&limit=${limit}&type=pos${
        channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
      }`
    )(dispatch);
  };

  const { product, auth, quotation, channel, layout, CSV } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedModelId, setSelectedModelId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  useEffect(() => {
    auth?.token &&
      getPricelistAction(
        auth?.token,
        `?${channel?.selected?._id ? `channel=${channel?.selected?._id}` : ""}`
      )(dispatch);
  }, [auth?.token, channel?.selected, dispatch]);

  useEffect(() => {
    dispatch(myProductActions.seEbmItem([]));
  }, [dispatch]);

  React.useEffect(() => {
    auth?.token &&
      channel?.selected?._id &&
      getPricelistItemAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}&type=pos${
          channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
        }`
      )(dispatch);
  }, [auth?.token, channel?.selected, dispatch, page, limit]);

  useEffect(() => {
    auth?.token &&
      getAllProductsAction(
        auth?.token,
        product?.query || "?limit=15"
      )(dispatch);
  }, [auth?.token, quotation?.query, quotation?.allRfqSupplier?.data]);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&type=pos${
            channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
          }`
        )(dispatch);
    }
  }, [searchKey, page, limit, auth?.token, channel?.selected?._id, dispatch]);

  const handleSearch = () => {
    searchKey &&
      auth?.token &&
      getPricelistSearchAction(
        auth?.token,
        `?sk=${searchKey}&type=pos${
          channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
        }`
      )(dispatch);
  };

  const csvData = CSV?.csvDownloadedData?.data?.map((d: any) => ({
    amount: getActivePrice(d?.prices)?.toLocaleString(),
    model: d?.product?.model,
    type: d?.product?.type,
    brand: d?.product?.brand,
    storage: d?.specification
      ?.filter((item: any) =>
        ["capacity (rom)", "capacity(rom)", "rom", "capacity"]?.includes(
          item[0]?.toLowerCase()
        )
      )
      ?.map((item: any) => item[1]),
    memory: d?.specification
      ?.filter((item: any) =>
        ["memory (ram)", "memory (ram)", "ram", "memory"]?.includes(
          item[0]?.toLowerCase()
        )
      )
      ?.map((item: any) => item[1]),
  }));

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllPriceList_CSVAction(
          auth?.token,
          `?hasSubs=false&page=0&limit=10000}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  return (
    <>
      <Stack spacing={1} className="xl:mt-5 mt-10">
        <div className="text-black items-center justify-between mt-7 my-auto flex ml-5 mr-20">
          <DetailsHeaderActionBar
            pageName={`${channel?.selected?.name} 's Plan`}
            goBack={goBack}
            title="Prices and Plans"
          />
        
        </div>
        <div className="flex items-center justify-between">
          <PriceListFilter isModalOpen={isModalOpen} />
          <div className="flex items-center space-x-4">
            <SearchInput
              onSearch={handleSearch}
              onChange={(e: any) => {
                e.preventDefault();
                setSearchKey(e.target.value);
              }}
            />
            <div className="flex items-center text-black space-x-2">
              {[
                "admin",
                "finance-manager",
                "sales-supervisor",
                "dev",
                "inventory",
                "b2b-relation",
                "sales-manager",
              ].includes(auth?.user?.role?.toLowerCase()) && (
                <>
                  <CSVExportButton
                    csvHeaders={PriceListHeaders}
                    csvData={csvData}
                    filename={`Sales Report.csv`}
                  />
                </>
              )}
            </div> <div>
          {["admin", "finance-manager", "dev"]?.includes(
            auth?.user?.role?.toLowerCase()
          ) && (
            <HeaderComponent
              item="Modal Device"
              modelTitle="New Modal Device"
              isNotCollapse={true}
              ModelComponent={
                <AddItem
                  setSelectedModelId={setSelectedModelId}
                  onCancel={handleCancel}
                />
              }
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              handleCancel={handleCancel}
            />
          )}
          </div></div>
        </div>
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.priceListItems?.total}
          count={Math.ceil(channel?.priceListItems?.total / limit)}
        >
          {channel?.isFetching && (
            <div className="flex flex-wrap gap-4 justify-start items-start">
              {Array.from({ length: 15 }).map((d: any) => (
                <div className="w-[30rem]">
                  <Skeleton animation="wave" />
                  <Skeleton variant="rectangular" height={150} />
                </div>
              ))}
            </div>
          )}
          {!channel.isFetching && (
            <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
              <ProductGrid data={channel?.priceListItems?.data} />
            </div>
          )}
        </ScrollableFrame>
      </Stack>
    </>
  );
};

export default PageView;
