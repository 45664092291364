import React from "react";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import CustomizedPieChart from "./CustomizedPieChart";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { useSelector } from "react-redux";
import PieChartLoader from "./pieChartLoader";
import "./loader.css";
const OrdersHistory = (props: any) => {
  const { company, overView } = useSelector((state: any) => state);
  const brandData = props?.data?.Brand;
  const noSaleData =
    brandData &&
    Object.entries(brandData)?.map(([name, value]) => ({
      name,
      value,
    }));
  const numberOfRequests = props?.data?.date1noSales?.reduce(
    (total: any, current: any) => total + current.numberOfRequests,
    0
  );

  const COLORS = [
    "#5F00FFB2",
    "#E25668B2",
    "#E256AEB2",
    "#E28956B2",
    "#CF56E2B2",
    "#8A56E2B2",
  ];
  return (
    <div className="w-full bg-[#fff] h-auto overflow-hidsden p-5">
      <div className="text-[#030229] text-lg flex justify-between">
        <h1 className="font-normal text-[16px] opacity-90 my-1">No Sales</h1>
        <FaArrowUpRightFromSquare color={"#03022980"} />
      </div>
      <div className=" text-[#030229]  font-normal text-[14px] ">
        <p>
          {getDefaultCurrencyCode(company)}
          {props?.data?.date1totalAmount?.toLocaleString() ?? 0}
        </p>
      </div>
      <div className="text-[#030229]  opacity-50 font-normal text-[14px]">
        {props?.periodValue === "daily" && (
          <p>
            {getDefaultCurrencyCode(company)}{" "}
            {props?.data?.date2totalAmount?.toLocaleString() ?? 0}
          </p>
        )}
      </div>
      {overView?.isFetching ? (
        <div className="w-1/2 flex  m-auto mr-10 mt-24">
          <PieChartLoader />
        </div>
      ) : (
        <CustomizedPieChart
          data={noSaleData}
          colors={COLORS}
          totalLabel="Total Devices"
          totalValue={numberOfRequests ?? 0}
          height={"270px"}
          contentWidth="w-[75%]"
          top={"50%"}
          left={"50%"}
          innerRad={80}
          outerRad={110}
          pieHeight={300}
          pieWidth={400}
        />
      )}
    </div>
  );
};
export default OrdersHistory;
