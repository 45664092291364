import React, { useState, useEffect } from "react";
import { Table, Tag, Tooltip, Badge } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllTransactionInRangeAction } from "../../../store/pos/actions";
import { searchValue } from "../../../utils/setColor";
import ScrollableFrame from "../../layout/ScrollableFrame";
import { QuickbookHeader, SalesReportHeader } from "../../csvHeaders/Headers";
import ProductFilter from "../../../components/filters/POSTransactionsFiltering";
import PaidOnForm from "../../forms/PaidOnForm";
import moment from "moment";
import ThreeDotDropdown from "./threeDotDropdown";
import DetailsModel from "../../Modals/DetailsModel";
import AmountPaidForm from "../../forms/AmountPaidAndPaymentMethodForm";

import CSVExportButton from "../../cards/NoSale/CSVExportButton";
import { getFinanceTransaction_CSVAction } from "../../../store/csvDownload/actions";
import { getActiveShop } from "../../../utils/converter";
import QuickBookExportButton from "../../cards/NoSale/QuickBookExportButton";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import DetailsHeaderActionBar from "../../cards/DetailsPage/DetailsHeaderActionBar";
interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const SalesTable = (props: any) => {
  const { pos, auth, layout, CSV, company } = useSelector(
    (state: any) => state
  );
  const [openPaidOnModal, setOpenPaidOnModal] = useState(false);
  const [openAmountModal, setOpenAmountModal] = useState(false);
  const channels = auth?.user?.channel;
  const initialChannel = channels && channels?.at(0)?._id;
  let today = moment();
  const todayMonth = today.format("MMMDD");
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState<any>(formattedPreviousDate);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(formattedDate);
  const [selectType, setSelectType] = useState("");
  const [selectBrand, setSelectBrand] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const [sellingMode, setSellingMode] = useState();
  const [setSelectedShop] = useState();
  const [selectedChannel, setSelectedChannel] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [paidOnData, setPaidOnData] = useState<any>("");
  const [dataToUpdate, setDataToUpdate] = useState<any>("");
  const [selectedAction, setSelectedAction] = useState("");

  const franchiseeShops =
    ["franchisee"]?.includes(auth?.user?.role) &&
    getActiveShop(auth?.user?.shop?.assigned)
      ?.map((el: any) => el?.shop?._id)
      ?.join(",");

  const paidOnModel = (value: any, action: string) => {
    setSelectedAction(action);
    setPaidOnData(value);
    setOpenPaidOnModal(true);
    setOpenAmountModal(false);
  };

  const onCancelPaidOnModel = () => {
    setPaidOnData("");
    setSelectedAction("");
    setOpenPaidOnModal(false);
  };

  const amountPaidModel = (value: any, action: any) => {
    setSelectedAction(action);
    setDataToUpdate(value);
    setOpenAmountModal(true);
    setOpenPaidOnModal(false);
  };

  const onCancelAmountPaidModel = () => {
    setDataToUpdate("");
    setSelectedAction("");
    setOpenAmountModal(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "paidOn",
      key: "paidOn",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, record) => (
        <>
          <p>{text || "N/A"}</p>
          <p>{record?.data?.cart?.customer?.phone || "N/A"}</p>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text, record) => (
        <>
          <p>{text}</p>
          {record?.data?.createdBy?.profile && (
            <p className="capitalize">{record?.data?.createdBy?.profile}</p>
          )}
        </>
      ),
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => (
        <>
          <p className="font-bold">
            {record?.data?.shopStockItem?.shop?.name ?? "N/A"}
          </p>
          <p className="font-bold">
            {record?.data?.shopStockItem?.shop?.channel?.name ?? "N/A"}
          </p>
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, record) => (
        <Tooltip title={`${record.specification}`}>
          <p>{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "SN/Bar Code",
      dataIndex: "barCode",
      key: "barCode",
    },
    {
      title: "Data Activated",
      dataIndex: "dataActivated",
      key: "dataActivated",
      render: (text: any, record: any) => (
        <p>{`${record?.dataActivated ? "Yes" : " No"}`}</p>
      ),
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <p>{record?.data?.payment?.amount?.toLocaleString()} </p>
      ),
    },
    {
      title: "Ex-Wty",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p>{record?.data?.payment?.extendedWarranty} %</p>
      ),
    },
    {
      title: "Disc",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <p>{record?.data?.cart?.discount?.discountPerc} %</p>
      ),
    },
    {
      title: "Amount Paid",
      dataIndex: "data",
      key: "data",
      render: (text, record) => (
        <Tag
          className="font-bold"
          color={
            record?.data?.payment?.amount +
              (record?.data?.payment?.amount *
                record?.data?.payment?.extendedWarranty) /
                100 -
              (record?.data?.payment?.amount *
                record?.data?.cart?.discount?.discountPerc) /
                100 ===
            record?.data?.cart?.payment?.reduce(
              (sum: number, a: any) => (sum = sum + a?.amount),
              0
            )
              ? "success"
              : record?.data?.payment?.amount +
                  (record?.data?.payment?.amount *
                    record?.data?.payment?.extendedWarranty) /
                    100 -
                  (record?.data?.payment?.amount *
                    record?.data?.cart?.discount?.discountPerc) /
                    100 >
                record?.data?.cart?.payment?.reduce(
                  (sum: number, a: any) => (sum = sum + a?.amount),
                  0
                )
              ? "error"
              : "cyan-inverse"
          }
        >
          {record?.data?.successPayment?.length > 0
            ? record?.data?.successPayment
                ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
                ?.toLocaleString()
            : record?.data?.cart?.payment
                ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
                ?.toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "payment",
      key: "payment",
      render: (_text: any, record: any) => (
        <p>
          {record?.data?.cart?.payment?.map((d: any) =>
            d?.mode?.split("_")?.join(" ")
          )}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text, record) => (
        <span className="flex items-center">
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[50px] text-center"
          >
            {record?.data?.status}{" "}
          </Tag>
          {record?.data?.receiptCode &&
            ["admin", "dev"]?.includes(auth?.user?.role) && (
              <Badge count={"RRA"} offset={[0, 0]} color="magenta"></Badge>
            )}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_text, record) => {
        return (
          <>
            {["admin", "dev"]?.includes(auth?.user?.role) &&
              record?.status?.toLowerCase() === "paid" && (
                <span className="flex items-center">
                  <ThreeDotDropdown
                    onPaidOn={() => paidOnModel(record?.data, "paidOn")}
                    onAmountPaid={() =>
                      amountPaidModel(record?.data?.cart, "amount")
                    }
                    onPaymentMethod={() =>
                      amountPaidModel(record?.data?.cart, "paymentMethod")
                    }
                    onRevertSale={() => paidOnModel(record?.data, "revert")}
                  />
                </span>
              )}
          </>
        );
      },
    },
  ];

  const encodedBrand = selectBrand && encodeURIComponent(selectBrand);

  useEffect(() => {
    if (selectedDate || selectedEndDate) {
      auth?.token &&
        getAllTransactionInRangeAction(
          auth?.token,
          `?startDate=${selectedDate}&endDate=${selectedEndDate}&limit=${limit}&page=${
            page - 1
          }&channel=${
            initialChannel ?? selectedChannel
          }&type=${selectType}&brand=${encodedBrand}${
            layout?.selectedShop ? `&shop=${layout?.selectedShop}` : ""
          }${
            !layout?.selectedShop
              ? ["franchisee"]?.includes(auth?.user?.role)
                ? `&shop=${franchiseeShops}`
                : ""
              : ""
          }${sellingMode ? `&saleMode=${sellingMode}` : ""}`
        )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    dispatch,
    limit,
    page,
    selectedStatus,
    selectedDate,
    selectedEndDate,
    selectType,
    encodedBrand,
    initialChannel,
    layout?.selectedShop,
    sellingMode,
  ]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getFinanceTransaction_CSVAction(
          auth?.token,
          `?startDate=${selectedDate}&endDate=${selectedEndDate}&limit=${
            pos?.transaction?.total
          }&page=0&channel=${
            initialChannel ?? selectedChannel
          }&type=${selectType}&brand=${encodedBrand}${
            layout?.selectedShop ? `&shop=${layout?.selectedShop}` : ""
          }${
            !layout?.selectedShop
              ? ["franchisee"]?.includes(auth?.user?.role)
                ? `&shop=${franchiseeShops}`
                : ""
              : ""
          }${sellingMode ? `&saleMode=${sellingMode}` : ""}`,
          CSV.fileToExport
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const value = pos?.transaction?.data?.transactions ?? pos?.transaction?.data;

  function processString(str: string) {
    const getRandomNumber = () => {
      return String(Math.floor(Math.random() * 1000)).padStart(3, "0");
    };

    const removeSpecificWords = (s: string) => {
      const wordsToRemove = ["SERVICE CENTER", "CONNECT SHOP"];
      let result = s;

      wordsToRemove?.forEach((word) => {
        const regex = new RegExp(word, "gi");
        result = result?.replace(regex, "");
      });

      return result;
    };

    str = str?.toUpperCase();
    str = removeSpecificWords(str);
    let strNoSpaces = str?.replace(/\s/g, "");

    if (strNoSpaces?.length <= 6) {
      if (strNoSpaces?.length < 6) {
        strNoSpaces += getRandomNumber();
      }
      return strNoSpaces?.substring(0, 6);
    }

    const removeVowelsExceptLast = (s: string) => {
      const vowels = "AEIOU";
      let lastVowelIndex = -1;

      for (let i = s?.length - 1; i >= 0; i--) {
        if (vowels?.includes(s[i])) {
          lastVowelIndex = i;
          break;
        }
      }

      if (lastVowelIndex === -1) {
        return s;
      }

      return s
        ?.split("")
        ?.filter((char: string, index: number) => {
          return !vowels?.includes(char) || index === lastVowelIndex;
        })
        ?.join("");
    };

    const processedStr = removeVowelsExceptLast(strNoSpaces);

    return processedStr?.substring(0, 6);
  }

  const dataToDisplay = value?.map((el: any) => {
    return {
      key: el?._id,
      date_time: el?.createdAt?.split("T")[0],
      paidOn: el?.paidOn?.slice(0, 10),
      agent: el?.createdBy?.names,
      model: el?.shopStockItem?.requestedItem?.product?.product?.model,
      specification: `${el?.shopStockItem?.requestedItem?.product?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      amount: el?.payment?.amount,
      status: el?.status,
      barCode: el?.warehouseProduct?.serialNumber,
      dataActivated: el?.warehouseProduct?.dataActivated,
      transactionId: el?.cart?.cartId,
      customer: el?.cart?.customer?.name,
      data: el,
    };
  });

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    const plan = el?.cart?.selectedPlan;
    const totalPlanAmount =
      parseInt(plan?.duration) * parseInt(plan?.monthlyInstalment) +
      parseInt(plan?.initialPayment);
    const docs = [
      ...(Array.isArray(el?.cart?.payment[0]?.reference)
        ? el?.cart?.payment[0]?.reference
        : []),
      ...(Array.isArray(el?.cart?.discount?.proof)
        ? el?.cart?.discount?.proof
        : []),
    ];
    return {
      date_time: el?.createdAt?.slice(0, 10),
      paidOn: new Date(el?.paidOn?.slice(0, 10))?.toLocaleDateString("en-GB"),
      customer: el?.cart?.customer?.name || "N/A",
      customerPhone: el?.cart?.customer?.phone || "N/A",
      agent: el?.createdBy?.names,
      agentPhone: el?.createdBy?.phone,
      status: el?.status,
      region: el?.shopStockItem?.shop?.region,
      shop:
        el?.shopStockItem?.shop?.type !== "service-center"
          ? el?.shopStockItem?.shop?.name +
            " " +
            el?.shopStockItem?.shop?.channel?.name
          : el?.shopStockItem?.shop?.name,
      model: el?.shopStockItem?.requestedItem?.product?.product?.model,
      type: el?.shopStockItem?.requestedItem?.product?.product?.type,
      brand: el?.shopStockItem?.requestedItem?.product?.product?.brand,
      barCode: el?.warehouseProduct?.serialNumber,
      imei2: el?.warehouseProduct?.imei2 ?? "N/A",
      dataActivated: el?.warehouseProduct?.dataActivated ? "YES" : "NO",
      newSimCard: el?.warehouseProduct?.isNewSimcard ? "YES" : "NO",
      storage: el?.shopStockItem?.requestedItem?.product?.specification
        ?.filter((item: any) =>
          ["capacity", "capacity (rom)", "rom", "capacity(rom)"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
      memory: el?.shopStockItem?.requestedItem?.product?.specification
        ?.filter((item: any) =>
          ["memory", "memory (ram)", "ram", "memory(ram)"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
      amount:
        el?.cart?.isSubscription === "subscription"
          ? totalPlanAmount
          : parseInt(el?.payment?.amount),
      exWarranty: el?.payment?.extendedWarranty,
      methodOfPayment: el?.cart?.payment
        ?.map((d: any) => d?.mode)
        ?.join(" and "),
      macyemacyeType: el?.cart?.payment
        ?.filter((d: any) => d?.mode === "Macye_Macye")
        ?.map((d: any) => d?.macyemacyeType?.split("_")?.join(" ") ?? "-"),
      discount: `${el?.cart?.discount?.discountPerc} %`,
      transactionId: el?.cart?.payment?.at(0)?.momoTransactionId ?? "N/A",
      rraCode: el?.receiptCode ?? "N/A",
      district: el?.shopStockItem?.shop?.shopLocation?.district ?? "N/A",
      documents: docs?.map((doc: any) => doc)?.join(" and "),
      totalAmount:
        el?.successPayment?.length > 0
          ? el?.successPayment
              ?.map((d: any) => d?.amount?.toLocaleString())
              ?.join(" + ")
          : el?.cart?.payment
              ?.map((d: any) => d?.amount?.toLocaleString())
              ?.join(" + "),
      agentProfile: el?.createdBy?.profile || "N/A",
      remainingAmount: totalPlanAmount - parseInt(el?.payment?.amount) || "N/A",
      businessProduct: el?.cart?.businessProduct?.name || "N/A",
    };
  });

  const getSpecificationValue = (specification: any, keys: any) =>
    specification?.find((item: any) =>
      keys.includes(item[0]?.toLowerCase())
    )?.[1] ?? "N/A";
  const formatDate = (date: any) =>
    new Date(date?.slice(0, 10))?.toLocaleDateString("en-GB");

  const csvQuickBookData = CSV?.csvDownloadedData?.data?.map(
    (el: any, index: number) => {
      const { shop, requestedItem } = el?.shopStockItem || {};
      const { product } = requestedItem || {};
      const { specification } = product || {};
      const paidOn = el?.paidOn || "";
      const shopName = shop?.name || "";
      const shopType = shop?.type?.split("-").join(" ") || "";
      const createdBy = el?.createdBy?.names || "";
      const customerName = el?.cart?.customer?.name || "";
      const customerPhone = el?.cart?.customer?.phone || "";
      const brand = product?.product?.brand || "";
      const model = product?.product?.model || "";
      const capacity = getSpecificationValue(specification, [
        "capacity",
        "capacity (rom)",
        "rom",
        "capacity(rom)",
      ]);
      const memory = getSpecificationValue(specification, [
        "memory",
        "memory (ram)",
        "ram",
        "memory(ram)",
      ]);
      const serialNumber = el?.warehouseProduct?.serialNumber || "";
      const paymentAmount = el?.payment?.amount || "";
      const date = moment(el?.paidOn?.slice(4, 10));

      return {
        invoiceNo: `${processString(shopName)}-${el?.successPayment
          ?.map((el: any) => el?.mode?.substring(0, 4)?.toUpperCase())
          ?.join("+")}-${date.format("MMMDD")?.toLocaleUpperCase()}-${
          index + 1
        }`,
        customer: `${shopName?.toUpperCase()}`,
        invoiceDate: formatDate(paidOn),
        dueDate: formatDate(paidOn),
        terms: "DUE ON RECEIPT ",
        location: "",
        memo: `${shopName} ${shopType
          ?.split("-")
          ?.join(
            " "
          )}/${createdBy}/${customerName}/${customerPhone}/${brand}:${model} ${capacity}+${memory} /${serialNumber}`,
        item: `${brand?.toUpperCase()}:${model?.toUpperCase()}`,
        itemQuantity: 1,
        itemRate: paymentAmount,
        itemAmount: paymentAmount,
        itemTaxCode: "EXEMPT",
        itemTaxAmount: "",
        currency: "",
        serviceDate: "",
      };
    }
  );

  return (
    <>
      <div className="relative text-black mt-4">
        <div>
          {" "}
          <div className="flex flex-row justify-between items-center gap-4">
            <DetailsHeaderActionBar pageName="Sales Report" title={`${""}`} />
          </div>
        </div>
        <ProductFilter
          setSelectedShop={setSelectedShop}
          setSelectedStatus={setSelectedStatus}
          setSelectedDate={setSelectedDate}
          setSelectedEndDate={setSelectedEndDate}
          setSelectedChannel={setSelectedChannel}
          selectedChannel={selectedChannel}
          selectedDate={selectedDate}
          selectedEndDate={selectedEndDate}
          setSelectType={setSelectType}
          setSelectBrand={setSelectBrand}
          selectType={selectType}
          selectBrand={selectBrand}
          limit={limit}
          page={page}
          route={props?.route}
          setSellingMode={setSellingMode}
          sellingMode={sellingMode}
        />
        <div className="flex items-center justify-between xmb-4 mx-4">
          <div></div>
          <div className="flex justify-end gap-4 py-4">
            <QuickBookExportButton
              csvHeaders={QuickbookHeader}
              csvData={csvQuickBookData}
              filename={`Quickbook on ${todayMonth} report.csv`}
            />
            <CSVExportButton
              csvHeaders={SalesReportHeader()}
              csvData={csvData}
              filename={`Sales Report.csv`}
            />
          </div>
        </div>
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={pos?.transaction?.total}
          count={Math.ceil(pos?.transaction?.total / limit)}
        >
          <Table
            columns={columns}
            dataSource={dataToDisplay}
            pagination={false}
            loading={pos?.isFetching}
          />
        </ScrollableFrame>
      </div>

      <DetailsModel
        isModalOpen={openPaidOnModal}
        handleOnCancel={onCancelPaidOnModel}
        component={
          <PaidOnForm
            cartId={paidOnData?.cart?._id}
            dataToUpdate={paidOnData}
            onCancel={onCancelPaidOnModel}
            selectedDate={selectedDate}
            selectedEndDate={selectedEndDate}
            action={selectedAction}
          />
        }
      />
      <DetailsModel
        isModalOpen={openAmountModal}
        handleOnCancel={onCancelAmountPaidModel}
        component={
          <AmountPaidForm
            cartId={dataToUpdate?._id}
            dataToUpdate={dataToUpdate}
            onCancel={onCancelAmountPaidModel}
            action={selectedAction}
            selectedDate={selectedDate}
            selectedEndDate={selectedEndDate}
          />
        }
      />
    </>
  );
};

export default SalesTable;
