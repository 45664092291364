import { DateRangePicker } from "react-date-range";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import NosaleTable from "../../../../components/tables/NosaleTable";
import { DatePicker, type TimeRangePickerProps } from "antd";
import dayjs from "dayjs";
import React from "react";
import moment from "moment";
import type { Dayjs } from "dayjs";

const Nosale = (_props: any) => {
  const { RangePicker } = DatePicker;
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(7, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  return (
    <div className="text-black py-2 scrollbar-hide">
      <div className="text-black items-center justify-between mt-1 my-auto flex ml-5 mr-20">
      <DetailsHeaderActionBar pageName="No sales" title=" " />
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-4">
            <span>Select Date:</span>
          </div>
          <RangePicker presets={rangePresets} onChange={onRangeChange} />
        </div>
        </div>
      <NosaleTable startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
    </div>
  );
};

export default Nosale;
