import React, { useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Badge, Card, Skeleton, Switch, Button, notification } from "antd";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateIntegrationAction } from "../../../store/setting/integrations/actions";
import {
  getIntegrationAction,
  getOneIntegrationAction,
} from "../../../store/setting/actions";
import { CopyOutlined } from "@ant-design/icons";

const IntergrationDeatails = (props: any) => {
  const { auth, setting } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [visibleApiKeys, setVisibleApiKeys] = useState<Record<string, boolean>>(
    {}
  );

  const toggleApiKeyVisibility = (id: string) => {
    setVisibleApiKeys((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleDefaulted = async (selectedId: string) => {
    if (!auth?.token) return;

    const existingIntegrationData = props?.data?.users || [];

    const updatedIntegration = existingIntegrationData.map((currency: any) => ({
      ...currency,
      isActive: currency._id === selectedId,
    }));

    const payload = {
      users: updatedIntegration,
    };

    auth.token &&
      (await updateIntegrationAction(
        auth.token,
        props?.data?._id,
        payload
      )(dispatch));

    auth.token &&
      (await getOneIntegrationAction(props?.data?._id, auth?.token)(dispatch));
  };

  React.useEffect(() => {
    auth?.token &&
      getOneIntegrationAction(props?.data?._id, auth?.token)(dispatch);
  }, [auth.token, dispatch, props?.data?._id]);

  React.useEffect(() => {
    auth?.token && getIntegrationAction(auth?.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  const handleCopy = (apikey: string) => {
    navigator.clipboard.writeText(apikey);
    notification.success({
      message: "Copied!",
      description: "API key has been copied to your clipboard.",
      duration: 2,
    });
  };

  return (
    <>
      <IoArrowBackCircleOutline
        size={24}
        className="opacity-60 cursor-pointer"
        onClick={() => props?.setShowDetails(false)}
      />
      <Badge.Ribbon
        text={
          <span style={{ fontSize: "12px" }}>
            {props?.data?.isConnected ? "connected" : "disconnected"}
          </span>
        }
        color={props?.data?.isConnected ? "[#605BFF]" : "red"}
        style={{ padding: ".3rem 1rem " }}
      >
        <div className="mt-8 w-full h-[25vh] text-[#0F0F47] border border-gray-200 rounded-md p-4 relative bg-white">
          <div className="flex gap-6">
            <Avatar
              alt="logo"
              src={props?.data?.logo}
              sx={{ width: 50, height: 50 }}
            />
            <div>
              <h1 className="font-medium text-lg mt-2">{props?.data?.name}</h1>

              <p className="font-normal text-sm mt-4">
                {props?.data?.description}
              </p>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
      <h1 className="my-6 font-medium text-lg px-2">Users</h1>
      <div className="flex flex-wrap gap-6 mt-4">
        {setting?.integration?.data &&
          (setting?.selected?.users || []).map((user: any) => {
            const isVisible = visibleApiKeys[user._id];
            return (
              <div
                key={user._id}
                className="w-[32rem] h-auto border border-[#030229] border-opacity-10 rounded-[10px] px-6 py-4 opacity-90 text-sm flex flex-col gap-2"
              >
                <div className="flex justify-between items-center mb-3">
                  <div className="flex gap-1 items-center">
                    <h1 className="font-medium text-[18px]">
                      {user?.name?.toUpperCase()}
                    </h1>
                  </div>
                  <Switch
                    size="small"
                    checked={user?.isActive}
                    onChange={() => handleDefaulted(user?._id)}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <p className="opacity-60">Api_Key :</p>
                      <h1 className="font-normal text-base break-words max-w-[20rem]">
                        {isVisible ? user?.apikey : "********"}
                      </h1>
                    </div>
                    {auth?.user?.role === "dev" && (
                      <div className="flex flex-row gap-1 align-center">
                        <Button
                          type="link"
                          size="small"
                          className="text-right"
                          onClick={() => toggleApiKeyVisibility(user._id)}
                        >
                          {isVisible ? "Hide" : "View"}
                        </Button>
                        {isVisible && (
                          <Button
                            className="cursor-pointer"
                            size="small"
                            onClick={() => handleCopy(user?.apikey)}
                          >
                            <CopyOutlined />
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex gap-1">
                  <p className="opacity-60">Description :</p>
                  <h1 className="font-normal text-base break-words w-[21rem]">
                    {user?.description}
                  </h1>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default IntergrationDeatails;
