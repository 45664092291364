import { notification } from "antd";
import { myIntegrationActions } from ".";
import { createIntegrationService, updateIntegrationService } from "./services";
import { myEmployeeActions } from "..";
import { getIntegrationService } from "../services";

export const createIntegrationAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myIntegrationActions.setIsFetching(true));
      const res = await createIntegrationService(data, token);
      if (res?.status === 201) {
        dispatch(myIntegrationActions.setNew(res));
        // dispatch(myIntegrationActions.setAll(resAll));
        dispatch(myIntegrationActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myIntegrationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateIntegrationAction = (
  token: string,
  id: string,
  data: {},
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myIntegrationActions.setIsFetching(true));
      const res = await updateIntegrationService(token, id, data);
      if (res?.status === 200) {
        dispatch(myIntegrationActions.setNew(res));
        dispatch(myIntegrationActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myIntegrationActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
