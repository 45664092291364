import React from "react";
import { PieChart, Pie, Cell } from "recharts";

interface PieProp {
  data?: any;
  colors?: any;
  totalLabel?: any;
  totalValue?: number;
  pieWidth?: number;
  pieHeight?: number;
  innerRad?: number;
  outerRad?: number;
  top?: any;
  left?: any;
  height?: any;
  contentWidth?: any;
}

export default function PlanOverviewPieChart({
  data,
  colors,
  totalLabel,
  totalValue,
  pieWidth = 300,
  pieHeight = 200,
  innerRad = 43,
  outerRad = 60,
  top = "50%",
  left = "37%",
  height = "180px",
  contentWidth = "w-full",
}: PieProp) {
  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "400px",
          height: height,
          margin: "0 auto",
        }}
      >
        <PieChart width={pieWidth} height={pieHeight}>
          <Pie
            data={data}
            innerRadius={innerRad}
            outerRadius={outerRad}
            fill="#605BFF"
            dataKey="value"
          >
            {data
              ? data?.map((entry: any, index: any) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors?.length]}
                  />
                ))
              : []}
          </Pie>
        </PieChart>
        <div
          style={{
            position: "absolute",
            top: top,
            left: left,
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            marginTop: "6px",
          }}
        >
          <p className="text-center font-medium text-base text-[#030229]">
            {totalValue}
          </p>
          <p className="text-center text-[#030229]">{totalLabel}</p>
        </div>
      </div>
      <div className={`${contentWidth} m-auto flex flex-col gap-3 mt-4`}>
        {data
          ? data?.map((entry: any, index: any) => (
              <div
                key={`legend-${index}`}
                className="flex items-center w-full px-3"
              >
                <div
                  className="w-4 h-4 mr-2 flex-shrink-0"
                  style={{
                    backgroundColor: colors[index % colors?.length],
                  }}
                />
                <div className="flex items-center w-full">
                  <div className="text-sm text-[#030229] w-36">{`${entry?.name} months plan`}</div>
                  <div className="text-sm text-[#030229] ml-2">{`(${entry?.value} Applications)`}</div>
                </div>
              </div>
            ))
          : []}
      </div>
    </div>
  );
}
