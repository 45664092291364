import React from "react";
import type { MenuProps } from "antd";
import { Button, Dropdown, Popconfirm, Spin } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateRepairStatusAction } from "../../../store/repair/actions";
import { GrFormView } from "react-icons/gr";
import { MdOutlineBeenhere, MdOutlineDone } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { IoCloseOutline, IoSwapHorizontal } from "react-icons/io5";

const ThreeDotDropdown = (props: any) => {
  const { auth, repairs } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  let items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          className="text-[#09090B] flex gap-2"
          onClick={() => props?.onShowDetails()}
        >
          <GrFormView size={26} color="#09090B" /> <p>View</p>
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          style={{
            color: "white",
            border: "none",
            backgroundColor: "transparent",
          }}
          onClick={() =>
            props?.record.repairedAt === "shop"
              ? updateRepairStatusAction(auth?.token, props?.record?.key, {
                  status: "completed",
                })(dispatch)
              : props?.onCompleteModel()
          }
          disabled={[
            "done",
            "completed",
            "transit-out",
            "pending",
            "cancelled",
            "send-to-office",
            "swap",
          ]?.includes(props?.record?.status)}
          // loading={repairs?.isFetching}
        >
          {repairs?.isFetching ? (
            <>
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            </>
          ) : (
            <div className=" flex gap-2">
              {" "}
              <MdOutlineDone size={23} color="#09090B" />{" "}
              <p className="text-[#09090B]">Complete</p>
            </div>
          )}
        </button>
      ),
    },
    {
      key: "3",
      label: (
        <>
          {props?.record?.status === "pending" ? (
            <Popconfirm
              title="Reject request"
              description={`Are you sure you want to deny the repair request for IMEI ${props?.record?.imei}?`}
              okButtonProps={{
                loading: repairs?.isFetching,
                className: "bg-blue-500",
              }}
              onConfirm={props?.handleRejectReq}
              okText="Yes"
              cancelText="No"
              disabled={props?.record?.status !== "pending"}
            >
              <button
               className="text-[#09090B] flex gap-2"
                disabled={props?.record?.status !== "pending"}
                onClick={() => props?.onReject()}
                style={{ width: 135 }}
              >
                <IoCloseOutline  size={25} color="#09090B" /> <p className="text-[#09090B]">Reject</p>
              </button>
            </Popconfirm>
           ) : (
            <Popconfirm
              title="In-Repairing"
              description="Are you sure this request has arrived?"
              okButtonProps={{
                loading: repairs?.isFetching,
                className: "bg-blue-500",
              }}
              onConfirm={props?.handleRepairingReq}
              okText="Yes"
              cancelText="No"
              disabled={props?.record?.status !== "transit-in"}
            >
              <button
                className="text-[#09090B] flex gap-3"
                style={{
                  width: 135,
                }}
                disabled={props?.record?.status !== "transit-in"}
                onClick={() => props?.onArrival()}
              >
                 <MdOutlineBeenhere  size={20} color="#09090B" className="mt-1"/> <p className="text-[#09090B] ">Arrived</p>
              </button>
            </Popconfirm>
          )
          }
        </>
      ),
    },
    {
      key: "4",
      label: (
        <button
        className="text-[#09090B] flex gap-3"
          disabled={[
            "done",
            "completed",
            "transit-out",
            "cancelled",
            "swap",
          ]?.includes(props?.record?.status)}
          onClick={() => props?.onSwap()}
        >
          {" "}
          <IoSwapHorizontal   size={20} color="#09090B" className="mt-1"/> <p className="text-[#09090B] ">Swap</p>
        </button>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <BsThreeDotsVertical color="black" />
    </Dropdown>
  );
};

export default ThreeDotDropdown;
