import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DetailsPage from "./DetailPage";
import { getOneCustomerAction } from "../../../store/customer/actions";
import RepairsTable from "../../../apps/repairs/components/RepairsTable";
import RepairFiltering from "../components/filter/RepairFiltering";

type TAB = {
  title: string;
  component: any;
};

const tabs: TAB[] = [
  {
    title: "Repairs",
    component: <RepairsTable />,
  },
];

const CustomerDetails = (props: any) => {
  const { customerId } = useParams();
  const { auth, repairs } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleClickEdit = () => {
    console.log("editing a customer....");
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (customerId) {
        console.log("fetching....");
        auth?.token &&
          (await getOneCustomerAction(auth?.token, customerId)(dispatch));
      }
    };
    fetchOnProduct();
  }, [customerId, auth?.token, dispatch]);

  return (
    <div className="py-4 xspace-y-4 h-full overflow-y-auto scrollbar-hide">
      <DetailsPage
        pageItemId={customerId}
        tabs={tabs}
        pageName={"Repairs"}
        title=" "
        simpleDetails={repairs && repairs.all?.data}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
      />
      {/* <RepairFiltering /> */}
    </div>
  );
};

export default CustomerDetails;
