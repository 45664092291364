/* eslint-disable no-mixed-operators */
//@ts-nocheck
import React, { useState } from "react";
import { Skeleton, Button } from "antd";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { GrFormNext } from "react-icons/gr";
import SubscriptionDrower from "../../../../components/Modals/SubscriptionDrower";
import { searchValue } from "../../../../utils/setColor";

const CurrentPlanSummary: React.FC = () => {
  const { account, deliverynote, orders } = useSelector((state: any) => state);
  const [currentContactIndex, setCurrentContactIndex] = useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const nextContact = () => {
    setCurrentContactIndex(
      (prevIndex) => (prevIndex + 1) % account.selected.contacts.length
    );
  };
  const requestId =
    account?.selected?.requests && account?.selected?.requests[0]?._id;
  const showDrawer = (d: any) => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const addressData =
    account?.selected?.customerDetails?.address &&
    account?.selected?.customerDetails?.address?.split(", ");
  return (
    <>
      <div className="text-[#030229] font-helv w-[100%] rounded-md bg-white flex flex-col md:flex-row space-x-9 p-6 relative">
        {account?.isFetching ? (
          <Skeleton />
        ) : (
          <>
            <div className="flex flex-col md:flex-row gap-6 mr-10 pb-2">
              <div>
                <Avatar
                  alt="account image"
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVw9x0c3GglD7ozfJFUyOdT4eFDuH9mxawEA&usqp=CAU"
                  }
                  sx={{ width: "3rem", height: "3rem" }}
                />{" "}
              </div>
              <div className="space-y-2 font-medium">
                <div className="font-poppins text-lg  leading-normal text-[#030229] capitalize">
                  {account?.selected?.customerDetails?.name}
                </div>
                <div className="flex items-center space-x-2">
                  <span>Tel:</span>
                  <span className="text-gray-800">
                    {" "}
                    {account?.selected?.customerDetails?.tel}
                  </span>{" "}
                </div>
                <div>
                  <span>Email: </span>
                  <span className="text-gray-800">
                    {account?.selected?.customerDetails?.email}
                  </span>
                </div>
                <div className="text-[#030229] font-poppins text-base font-normal leading-normal flex flex-row">
                  <span className="capitalize pl-1">
                    {addressData?.map((line: any, index: number) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />} {line?.trim()}{" "}
                      </React.Fragment>
                    ))}
                  </span>
                </div>
                <div className="rounded-md px-4 text-white w-20 cursor-pointer" style={{ background: searchValue(account.selected?.channel?.status) }}>
                  {account.selected?.channel?.status}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 ">
              {account?.selected?.contacts?.length > 0 && (
                <div className="flex flex-row font-helv rounded-md border-l mr-10 pl-10 items-center">
                  <div>
                    <div className="flex flex-row  gap-10 space-y-2">
                      <div className="flex flex-row  items-center gap-10 justify-between">
                        <div className="font-poppins text-lg leading-normal text-[#030229] pl-2 pb-1">
                          Contacts person
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span className="px-2">Name:</span>
                        <span
                          className={`text-gray-500 ${account.selected.contacts[currentContactIndex]
                            .nameWarning
                            ? "text-red-500"
                            : ""
                            }`}
                        >
                          {account.selected.contacts[currentContactIndex].name}
                        </span>
                      </div>
                      <div>
                        <span className="px-2">Phone: </span>
                        <span
                          className={`text-gray-500 ${account.selected.contacts[currentContactIndex]
                            .phoneWarning
                            ? "text-red-500"
                            : ""
                            }`}
                        >
                          {account.selected.contacts[currentContactIndex].phone}
                        </span>
                      </div>
                      <div>
                        <span className="px-2">Email </span>
                        <span
                          className={`text-gray-500 ${account.selected.contacts[currentContactIndex]
                            .relationshipWarning
                            ? "text-red-500"
                            : ""
                            }`}
                        >
                          {(account?.selected?.contacts &&
                            account?.selected?.contacts[currentContactIndex]
                              ?.email) ||
                            " N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-4 pl-2">
                      <div className="text-sm text-gray-500">
                        {currentContactIndex + 1} /{" "}
                        {account.selected.contacts.length}
                      </div>
                      {account.selected.contacts.length > 1 && (
                        <div
                          className="mt-4 p-2 text-[#605BFF] border border-[#EBEFF2] rounded-md cursor-pointer"
                          onClick={nextContact}
                        >
                          <GrFormNext />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col md:flex-row gap-2 pb-2">
              <div className="space-y-2 font-helv w-96 pt-3 border-l pl-10">
                <div className="flex items-center space-x-4 font-medium">
                  <span className="text-md">Order Date:</span>
                  <span className="text-gray-500">
                    {" "}
                    {account?.selected?.updatedAt?.slice(0, 10)}
                  </span>{" "}
                </div>
                <div className="flex items-center space-x-4 font-medium">
                  <span className="text-md">PO#:</span>
                  <span className="text-gray-500">
                    {" "}
                    {orders?.selected?.SOID}
                  </span>{" "}
                </div>
                <div className="flex items-center space-x-4 font-medium">
                  <span className="text-md">Delivery Note#:</span>
                  <span className="text-gray-500">
                    {" "}
                    {deliverynote?.singleDeliveryNote?.SDNID}
                  </span>{" "}
                </div>
                <div className="flex items-center space-x-4 font-medium">
                  <span className="text-md">Customer ID:</span>
                  <span className="text-gray-500">
                    {" "}
                    {account?.selected?.accountID}
                  </span>{" "}
                </div>
                <div className="flex items-center space-x-4 font-medium">
                  <span className="text-md">Despatch Date:</span>
                  <span className="text-gray-500"> {"N/A"}</span>{" "}
                </div>
              </div>
            </div>
          </>
        )}
        {account?.selected?.type?.toLowerCase() !== "b2b" && (
          <div className="absolute top-3 right-3">
            <Button type="primary" onClick={showDrawer} requestId={requestId} className="pt-1 bg-[#605BFF]"><p className="text-sm font-medium">View Details</p></Button>
          </div>
        )}
      </div>
      <SubscriptionDrower
        open={openDrawer}
        onClose={onCloseDrawer}
        data={account?.selected}
      />
    </>
  );
};
export default CurrentPlanSummary;
