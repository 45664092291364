import React from "react";
import { Col, Divider, Row } from "antd";

interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div className="flex items-center justify-between w-full">
    <p className="font-semibold text-sm w-2/5">{title}</p>
    <span className="w-3/5">{content}</span>
  </div>
);

const DelivererIn: React.FC<{
  data: any;
}> = ({ data }) => {
  return (
    <div>
      <p
        className="text-[#030229] text-base font-medium"
      >
        Deliverer In
      </p>
      <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Name</p>
          <p className="text-[#030229] text-sm">
            {data?.name}
          </p>
        </div>
      <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Company</p>
          <p className="text-[#030229] text-sm">
            {data?.company}
          </p>
        </div>
      <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Telephone</p>
          <p className="text-[#030229] text-sm">
            {data?.phone}
          </p>
        </div>
      <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">Email</p>
          <p className="text-[#030229] text-sm">
            {data?.email}
          </p>
        </div>
      <div className="flex justify-between py-1">
          <p className="text-[#030229B2] text-sm">date</p>
          <p className="text-[#030229] text-sm">
            {data?.date?.slice(0, 10)}
          </p>
        </div>
      <Divider dashed/>
    </div>
  );
};

export default DelivererIn;
