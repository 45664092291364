import React, { useState } from "react";
import { Table, Tag, Drawer, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { useDispatch, useSelector } from "react-redux";
import { searchValue } from "../../../../utils/setColor";
import ThreeInvoiceDotDropdown from "./billingAction/ThreeInvoiceDotDropdown";
import Invoice from "./Invoice";
import BillingHeader from "./acountHeaders/billingHeader";
import { mySubscritionActions } from "../../../../store/subscription/deliverynote";
import { handleClearAllState } from "../../../../utils/converter";
import "./components/tableHeader.css";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import MomoWaitingAndSuccessForm from "../../../../components/forms/MomoWaitingAndSuccessForm";
import { myInvoices } from "../../../../store/invoice";

interface DataType {
  key: any;
  bill: any;
  value: any;
  month: any;
  date: any;
  approvedby: any;
  paidon: any;
  status: any[];
}

const BillingTable = (props: any) => {
  const { invoice, account, company } = useSelector((state: any) => state);
  const [openInvoiceView, setOpenInvoiceView] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState("");
  const dispatch = useDispatch();
  const clearActions = [mySubscritionActions.setInvoiceResult];

  const onOpenViewModel = (value: any) => {
    setOpenInvoiceView(true);
    setDataToDisplay(value);
  };

  const onCancelViewModel = () => {
    setDataToDisplay("");
    setOpenInvoiceView(false);
    handleClearAllState(dispatch, clearActions);
  };

  const handleCloseMomoModel = () => {
    dispatch(myInvoices.setIsPaymentModalOpen(false));
    dispatch(myInvoices.setSelectedForPayment(null));
    dispatch(myInvoices.setPaymentInitiationStatus(false));
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Billing Invoice",
      dataIndex: "bill",
      key: "bill",
    },
    {
      title: `Amount (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amountTobePaid",
      key: "amountTobePaid",
      render: (text, record) => <p>{text?.toLocaleString()}</p>,
    },
    {
      title: `Amount (${getDefaultCurrencyCode(company)}) Paid`,
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (text, record: any) => (
        <p
          className={`${
            text > 0 && record?.amountTobePaid > text ? "text-red-500" : ""
          }`}
        >
          {record?.status !== "paid" ? "N/A" : text?.toLocaleString()}
        </p>
      ),
    },
    {
      title: "Starting Date",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Due date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Approved By",
      dataIndex: "approvedby",
      key: "approvedby",
    },
    {
      title: "Paid on",
      dataIndex: "paidon",
      key: "paidon",
      render: (text, record: any) => (
        <p>{record?.status !== "paid" ? "N/A" : text}</p>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text, record) => (
        <Tag color={searchValue(text)} className="capitalize w-18">
          <p className="px-2">{text}</p>
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <ThreeInvoiceDotDropdown
          onOpenViewModel={() => onOpenViewModel(record)}
          record={record}
        />
      ),
    },
  ];

  const invoiceData = invoice?.all?.data?.map((el: any) => {
    return {
      key: el?._id,
      bill: el?.transaction,
      value: el?.payment?.amount || el?.amountToBePaid,
      amountPaid: el?.payment?.amount,
      amountTobePaid: el?.amountToBePaid,
      month: el?.date?.slice(0, 10),
      date: el?.dueDate?.slice(0, 10),
      approvedby: el?.createdBy?.names,
      paidon: el?.payment?.date?.slice(0, 10),
      status: el?.status,
      type: el?.account?.type,
      data: el,
    };
  });

  return (
    <>
      <div className="xbg-[#F4F6FA] ">
        <div className="overflow-x-auto">
          <BillingHeader />
        </div>
        <ScrollableFrame
          setPage={props?.setPage}
          setLimit={props?.setLimit}
          limit={props?.limit}
          total={invoice?.all?.total}
          count={Math.ceil(invoice?.all?.total / props?.limit)}
        >
          <div className="overflow-x-auto">
            <Table
              columns={columns}
              dataSource={invoiceData}
              loading={invoice?.isFetching || account?.isFetching}
              pagination={false}
            />
          </div>
        </ScrollableFrame>
      </div>
      <Drawer
        placement="right"
        width={"40%"}
        onClose={onCancelViewModel}
        open={openInvoiceView}
      >
        <Invoice
          dataToDisplay={dataToDisplay}
          openInvoiceView={openInvoiceView}
        />
      </Drawer>

      <Modal
        title={""}
        open={invoice?.isPaymentModalOpen}
        onCancel={handleCloseMomoModel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div className="relative">
          <div className="w-[50vh]">
            {
              <MomoWaitingAndSuccessForm
                data={invoice?.selectedForPayment}
                onCancel={handleCloseMomoModel}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  );
};
export default BillingTable;
