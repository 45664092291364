import { LoadingButton } from "@mui/lab";
import { Form, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateSubRequestAction } from "../../store/subscription/subscriptionRequest/actions";

const ConfirmSubscriptionPayment = (props: any) => {
  const { auth, subscriptionRequest } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    const payload = {
      subscriptionStatus: "PAYMENT_DONE",
      comment: `The customer's payment was confirmed manually because, ${values.comment}`,
    };

    const res = await updateSubRequestAction(
      auth?.token,
      props?.dataToUpdate?.key,
      payload,
      "?limit=10&page=0"
    )(dispatch);
    if (res) {
      notification.success({
        message: "The customer's payment was successfully confirmed",
      });
      form.resetFields();
      props?.onClose();
    }
  };

  return (
    <div className="relative">
      <Form
        form={form}
        layout="vertical"
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <div className="mb-4">
          <h1 className="pb-4 font-semibold text-xl text-center">{`Confirm the ${props?.dataToUpdate?.name}'s Payment`}</h1>
          <span className="text-gray-500 text-base">{`Are you sure the customer has paid and the finance team has confirmed that they received the money?`}</span>
        </div>

        <Form.Item
          name="comment"
          label={<span className="text-lg">Reason</span>}
          rules={[
            {
              required: true,
              message: "Reason is required!",
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item>
          <div className="flex items-center justify-center w-full">
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "50%" }}
              loading={subscriptionRequest?.isFetching}
              className="h-10"
            >
              Submit
            </LoadingButton>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfirmSubscriptionPayment;
