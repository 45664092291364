import React from "react";
import SubscriptionRequestTable from "../../../components/tables/SubscriptionRequestTable";

const SubscriptionRequest = (_props: any) => {
  return (
    <div className="text-black py-6">
      <SubscriptionRequestTable />
    </div>
  );
};

export default SubscriptionRequest;
