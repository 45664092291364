import { notification } from "antd";
import { myEmployeeActions } from ".";
import { authActions } from "../auth";
import store from "store";
import {
  createServiceUser,
  getAllServiceUser,
  deleteServiceUser,
  getOneServiceUser,
  updateServiceUser,
  getOneServiceAgent,
  getTransactionServiceAgent,
  getAgentShopService,
  assignAgentToShop,
  deactivateAgentFromShop,
  changePasswordService,
  getIntegrationService,
  getRoleService,
  updatePasswordService,
  getPreferencesService,
  getThemeService,
  getOneThemeService,
  updateThemeService,
  updatePreferenceService,
  getActivityLogsService,
  getUserLogedInLogsService,
  getSelectedUserLogsService,
  getUserStatisticsService,
  getOneIntegrationService,
} from "./services";

export const createUserAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await createServiceUser(data, token);
      const resAll = await getAllServiceUser(token, "");

      // console.log(">>>>>>>:: user", resCreated );
      if (res?.status === 201) {
        dispatch(myEmployeeActions.setNew(res));
        dispatch(myEmployeeActions.setAll(resAll));
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({
          message: "Created. Check Email to change Password",
        });
      } else if (res?.code === "ERR_BAD_REQUEST") {
        notification.error({
          message: "Email Already Exist",
        });
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneUserAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getOneServiceUser(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setSelected(res?.data));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllUsersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getAllServiceUser(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setAll(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getUserStatisticsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getUserStatisticsService(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setUserStatistics(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteUserActions = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await deleteServiceUser(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllServiceUser(token, "");
        dispatch(myEmployeeActions.setAll(resAll));
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
        return true;
      }
      dispatch(myEmployeeActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateUserActions = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await updateServiceUser(itemId, data, token);
      // console.log("<<<<updated data>>>>", res)
      if (res?.status === 200) {
        const resAll = await getAllServiceUser(token, "");
        dispatch(myEmployeeActions.setSelected(res?.data));
        dispatch(myEmployeeActions.setAll(resAll));
        dispatch(myEmployeeActions.setIsFetching(false));
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneAgentShopAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetchingShop(true));
      const res = await getOneServiceAgent(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setSelected(res?.data));
        dispatch(myEmployeeActions.setIsFetchingShop(false));
      }
      dispatch(myEmployeeActions.setIsFetchingShop(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAgentShopsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getAgentShopService(token, query);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setAgent(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAgentTransactionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getTransactionServiceAgent(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setAgentTransaction(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const assignAgentToShopAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await assignAgentToShop(itemId, data, token);
      // console.log("<<<<Assigned Agent>>>>", res);
      if (res?.status === 200) {
        const resAll = await getAllServiceUser(token, "");
        dispatch(myEmployeeActions.setAll(resAll));
        dispatch(myEmployeeActions.setUpdated(res));
        dispatch(myEmployeeActions.setIsFetching(false));
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// Deactivate
export const deactivateAgentToShopAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await deactivateAgentFromShop(itemId, data, token);
      // console.log("<<<<Assigned Agent>>>>", res);
      if (res?.status === 200) {
        const resAll = await getAllServiceUser(token, "");
        dispatch(myEmployeeActions.setAll(resAll));
        dispatch(myEmployeeActions.setUpdated(res));
        dispatch(myEmployeeActions.setIsFetching(false));
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const ChangePasswordAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await changePasswordService(data, token);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({ message: "Password Updated Succesfully" });
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getIntegrationAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getIntegrationService(token, query);
      // console.log(">>>>!!!>>>:herrreee:)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setIntegration(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneIntegrationAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getOneIntegrationService(id, token);

  
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setSelected(res?.data));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.error(err);
    }
  };
};

export const getRoleAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getRoleService(token, query);
      // console.log(">>>>!!!>>>:herrreee:)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setRole(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPreferenceAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getPreferencesService(token);
      // console.log(">>>>!!!>>>:preferences:)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setPreference(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getThemeAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getThemeService(token);
      // console.log(">>>>!!!>>>:preferences:)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setTheme(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneThemeAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getOneThemeService(id, token);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setSelectedTheme(res?.data));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const updatePreferenceAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await updatePreferenceService(itemId, data, token);
      // console.log("dispatching the action.....")
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        const resAll = await getPreferencesService(token);
        dispatch(myEmployeeActions.setPreference(resAll));
        dispatch(myEmployeeActions.setSelected(res?.data));
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({ message: "Updated !" });
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      // console.log("an error occured......")
      console.log(err);
    }
  };
};
export const updateThemeAction = (token: string, itemId: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await updateThemeService(itemId, data, token);
      // console.log("dispatching the action.....")
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        const resAll = await getThemeService(token);
        dispatch(myEmployeeActions.setAll(resAll));
        dispatch(myEmployeeActions.setSelectedTheme(res?.data));
        dispatch(myEmployeeActions.setIsFetching(false));
        notification.success({ message: "Updated !" });
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      // console.log("an error occured......")
      console.log(err);
    }
  };
};
export const getActivityLogsAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getActivityLogsService(token);
      // console.log(">>>>!!!>>>:activity:)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setActivityLogs(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getUserLogedInLogsAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getUserLogedInLogsService(token);
      // console.log(">>>>!!!>>>:activity:)) ", res);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setUserLogedInLogs(res));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getSelectedUserLogsAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEmployeeActions.setIsFetching(true));
      const res = await getSelectedUserLogsService(id, token);
      if (res?.status === 200) {
        dispatch(myEmployeeActions.setSelectedUserLogs(res?.data));
        dispatch(myEmployeeActions.setIsFetching(false));
      }
      dispatch(myEmployeeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export { myEmployeeActions };
