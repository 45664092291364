import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "overViewLibrary",
  initialState: {
    isFetching: false,
    allPaymentHistory: { data: [] },
    noSale: null,
    totalRevenue: null,
    quantityRevenue: null,
    liveStockAtShop:null,
    liveStockAtWarehouse:null,
    devicesFinancedUnderChannels:null,
    devicesFinancedUnderBusinessProducts:null,
    recentPayment:null,
    statsPlan:null
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllPaymentHistory(state, action) {
      state.allPaymentHistory = action.payload;
    },
    setNoSale(state, action) {
      state.noSale = action.payload;
    },
    setTotalRevenue(state, action) {
      state.totalRevenue = action.payload;
    },
    setQuantityRevenue(state, action) {
      state.quantityRevenue = action.payload;
    },
    setLiveStockAtShop(state, action) {
      state.liveStockAtShop = action.payload;
    },
    setLiveStockAtWarehouse(state, action) {
      state.liveStockAtWarehouse = action.payload;
    },
    setDevicesFinancedUnderChannels(state, action) {
      state.devicesFinancedUnderChannels = action.payload;
    },
    setDevicesFinancedUnderBusinessProducts(state, action) {
      state.devicesFinancedUnderBusinessProducts = action.payload;
    },
    setRecentPayment(state, action) {
      state.recentPayment = action.payload;
    },
    setStatsPlan(state, action) {
      state.statsPlan = action.payload;
    },
  },
});

export const mySubscritionActions = mySlice.actions;

export default mySlice.reducer;
