import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stack, Box } from "@mui/material";
import {
  DatePicker,
  DatePickerProps,
  Tag,
  Table,
  Divider,
  Space,
  Skeleton,
} from "antd";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { getAllSubscriptionOrdersAction } from "../../../../store/subscription/order/actions";
import { searchValue } from "../../../../utils/setColor";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { TbGridDots } from "react-icons/tb";
import { ColumnsType } from "antd/es/table";
// import CsvDownloadButton from "../../../../components/buttons/CsvDownloadButton";
import { VscFilePdf } from "react-icons/vsc";
import FilesCard from "../../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { handleClearAllState } from "../../../../utils/converter";
import { mySubscritionActions } from "../../../../store/subscription/order";
import { picklistIncludeItem } from "../../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";

const PicklistCard = (props: any) => {
  const navigate = useNavigate();
  const { auth, orders } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const dataMapper: any[] = [];
  const [formData, setformData] = useState(dataMapper);
  const [activePickList, setActivePickList] = useState("pickList as grid");
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const value = orders?.all?.data;

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  useEffect(() => {
    auth?.token &&
      getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=pick-list&status=ready-for-pickup&status=pickup&limit=${limit}&page=${page - 1
        }&field=${picklistIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page]);
  interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "SID",
      dataIndex: "customerNumber",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Devices",
      dataIndex: "deviceNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className="text-md rounded-md w-18 py-[2px] px-6"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];
  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "SID", key: "customerNumber" },
    { label: `Created By `, key: "createdBy" },
    { label: `Date`, key: "date" },
    { label: `Time `, key: "time" },
    { label: `Devices`, key: "deviceNumber" },
    { label: `Status`, key: "status" },
  ];
  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          dataIndex: el._id,
          name: el?.account?.customerDetails?.name?.toUpperCase(),
          customerNumber: el?.SOID,
          deviceNumber: el?.list?.length,
          createdBy: el?.createdBy?.names,
          date: el?.createdAt?.substring(0, 10),
          time: el?.createdAt?.substring(11, 16),
          status: el?.status,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [value]);
  const handleIconClick = (iconType: string) => {
    setActivePickList(iconType);
  };

  const clearActions = [mySubscritionActions?.setSelected];
  useEffect(() => {
    handleClearAllState(dispatch, clearActions);
  }, []);

  return (
    <>
      <div className=" text-[#0F0F47] my-2">
        <div className="flex flex-wrap px-4 justify-between pt-4">
        <DetailsHeaderActionBar pageName={props?.routeName} title="Picklist" />
        <DatePicker onChange={onChange} className={"w-48"} />
        </div>
      </div>
      <Stack spacing={3} className="bg-white rounded h-full">
        <div className="flex flex-wrap px-4 justify-between pt-4">
          <div className="flex flex-row items-center"></div>
          <div className="flex flex-row items-center gap-4">
            <div className="text-gray-500 flex flex-row px-4">
              <div
                style={{
                  color:
                    activePickList === "pickList as grid" ? "#3b82f6" : "gray",
                  cursor: "pointer",
                }}
              >
                <TbGridDots
                  size={30}
                  onClick={() => handleIconClick("pickList as grid")}
                />
              </div>

              <AiOutlineUnorderedList
                size={30}
                onClick={() => handleIconClick("pickList as table")}
                className={`cursor-pointer ${activePickList === "pickList as table"
                  ? "fill-blue-500"
                  : "fill-gray-500"
                  }`}
              />
              {activePickList === "pickList as table" &&
                formData.length > 0 && (
                  <>
                    <div className="w-[3px] h-7 mx-2 bg-gray-300"></div>
                    <div className="flex justify-end gap-4 ">
                      <FilesCard
                        Icon={VscFilePdf}
                        tailwindTextColor="text-white"
                        tailwindBackgroundColor="bg-orange-400"
                        disabled
                      />
                      <CSVExportButton
                        csvHeaders={csvHeaders}
                        csvData={formData}
                        filename={`Pick List.csv`}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        {activePickList === "pickList as grid" && (
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
            customSize={300}
          >
            <div className="flex flex-wrap gap-6 bg-white w-full justify-start">
              {orders?.isFetching ? (
                <div className="flex flex-wrap gap-6 ">
                  {Array.from({ length: 5 })?.map(() => (
                    <div className="bg-white w-[370px] h-[170px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6">
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {orders?.all?.data?.map((item: any) => {
                    const qnty = item?.list?.reduce(
                      (curr: any, item: any) => curr + item?.quantity,
                      0
                    );
                    return (
                      <div
                        key={item._id}
                        className="bg-white rounded-[10px]  s h-[170px] w-[370px] pb-2  p-4 shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] relative text-[#030229] font-normal"
                      >
                        <Link to={`/${props?.routeName}/picklist/${item._id}`}>
                          <div className="flex flex-row justify-between">
                            <div>
                              <h2 className="text-[14px] font-normal opacity-90  font-poppins ">
                                {item?.account?.customerDetails?.name?.toUpperCase()}
                              </h2>
                              <p className="text-[#605BFF] text-[12px] font-normal mt-1">
                                {item?.SOID}
                              </p>
                            </div>
                            <div>
                              <Tag
                                color={searchValue(item?.status)}
                                className="text-[10px] rounded-md w-18 py-[2px] px-4 capitalize text-[#605BFF] border-[#605BFF]"
                              >
                                {item?.status?.split("-").join(" ")}
                              </Tag>
                            </div>
                          </div>
                          <div>
                            <Divider />
                          </div>
                          <div className="flex flex-col">
                            <Space>
                              <p className="opacity-60 text-[12px]">
                                Created by :{" "}
                              </p>
                              <p className="text-[12px]">
                                {item?.createdBy?.names}
                              </p>
                            </Space>
                            <Space>
                              <p className="opacity-60 text-[12px]">Date : </p>
                              <p className="text-[12px]">
                                {item?.createdAt?.substring(0, 10)}
                              </p>
                            </Space>
                            <Space>
                              <p className="opacity-60 text-[12px]">Time : </p>
                              <p className="text-[12px]">
                                {item?.createdAt?.substring(11, 16)}
                              </p>
                            </Space>
                          </div>
                          <p className="text-[12px] absolute right-3 bottom-3">
                            {`${item?.list?.length} Models / ${qnty} Qnty`}
                          </p>
                        </Link>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </ScrollableFrame>
        )}
        {activePickList === "pickList as table" && (
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
            customSize={300}
          >
            <Table
              onRow={(record: any, _rowIndex: any) => {
                return {
                  onClick: () => {
                    navigate(`/subscription/picklist/${record?._id}`);
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={orders?.isFetching}
            />
          </ScrollableFrame>
        )}
      </Stack>
    </>
  );
};
export default PicklistCard;
