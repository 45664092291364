import React from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { LiaFileExportSolid } from "react-icons/lia";

interface CSVTableDownloadProps {
  columns?: any[];
  csvHeaders: { label: string; key: string }[];
  csvData: any[];
  filename: any;
}

const ExportCSVFile: React.FC<CSVTableDownloadProps> = ({
  csvHeaders,
  csvData,
  filename,
}) => {
  const csvLinkRef = React.useRef<any>(null);

  const handleButtonClick = () => {
    if (csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  return (
    <>
      <Tooltip
        color="#605BFF"
        title={<h1 className="text-white"> Export CSV</h1>}
      >
        <Button
          onClick={handleButtonClick}
          icon={<LiaFileExportSolid size={24} />}
          style={{ width: "40px", height: "40px" }}
          className="p-4"
        ></Button>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          ref={csvLinkRef}
          filename={filename}
        />
      </Tooltip>
    </>
  );
};

export default ExportCSVFile;
