import React, { useEffect } from "react";
import { Select, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../utils/setColor";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import SearchInput from "../../buttons/SearchButton";
import { getAllSaveTheChildrenAction } from "../../../store/report/actions";
import { myReportActions } from "../../../store/report";

interface DataType {
  key: React.Key;
  fname: string;
  lname: string;
  age: number;
  address: string;
}

const SavetheChildrentable = (props: any) => {
  const { report, company, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const columns: ColumnsType<DataType> = [
    {
      title: "Due Date",
      dataIndex: "date",
    },
    {
      title: "First Name",
      dataIndex: "fname",
    },
    {
      title: "Last Name",
      dataIndex: "lname",
    },
    {
      title: `Monthly Payment(${getDefaultCurrencyCode(company)})`,
      dataIndex: "mPayment",
    },
    {
      title: `Amount Paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "mPaid",
    },
    {
      title: `Amount Remain (${getDefaultCurrencyCode(company)})`,
      dataIndex: "mPaidremain",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, record: any) => (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      ),
    },
  ];

  const dataToDisplay = report?.saveTheChildren?.data?.list?.map(
    (item: any) => {
      return {
        key: "",
        date: item?.dueDate?.slice(0, 10),
        fname: item?.account?.customerDetails?.name?.split(" ")[0],
        lname: item?.account?.customerDetails?.name?.slice(
          item?.account?.customerDetails?.name?.split(" ")[0]?.length
        ),
        mode: item?.account?.deliveryNote?.list?.at(0)?.orderItem?.product
          ?.product?.model,
        imei: item?.account?.deliveryNote?.list?.at(0)?.wareHouseItem
          ?.serialNumber,
        capacity: item?.account?.deliveryNote?.list
          ?.at(0)
          ?.orderItem?.product?.specification.filter(
            (item: any) => item[0]?.toLowerCase() === "capacity (rom)"
          )
          .map((item: any) => item[1]),
        mPayment: item?.amountToBePaid,
        mPaid: item?.amountPaid,
        mPaidremain: item?.amountToBePaid - item?.amountPaid,
        status: item?.status,
      };
    }
  );

  const onChange = (value: string) => {
    props?.setSelectedStatus(value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (auth?.token && [""]?.includes(report?.searchResult)) {
      getAllSaveTheChildrenAction(
        auth?.token,
        `?month1=${props?.startMonth}&month2=${props?.endMonth}${
          props?.status ? `&status=${props?.status}` : ""
        }&limit=${props?.limit}&page=0${
          props?.channelId ? `&channel=${props?.channelId}` : ""
        }`
      )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.searchResult]);

  const handleSearch = () => {
    auth?.token &&
      getAllSaveTheChildrenAction(
        auth?.token,
        `?month1=${props?.startMonth}&month2=${props?.endMonth}${
          props?.status ? `&status=${props?.status}` : ""
        }&limit=${props?.limit}&page=0${
          props?.channelId ? `&channel=${props?.channelId}` : ""
        }&customer=${report?.searchResult}`
      )(dispatch);
  };

  return (
    <div className="bg-white p-4 text-gray-900 xh-[67vh]">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4 pb-6 w-full">
          <h1 className="font-semibold">Payment History</h1>
          <Select
            showSearch
            placeholder="Filter by Status"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={filterOption}
            defaultValue={"All"}
            allowClear
            style={{ width: "10%" }}
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "pending",
                label: "Pending",
              },
              {
                value: "paid",
                label: "Paid",
              },
              {
                value: "late",
                label: "Late",
              },
            ]}
          />
        </div>
        <SearchInput
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            dispatch(myReportActions.setSearchResult(e.target.value));
          }}
        />
      </div>
      <ScrollableFrame
        customSize={500}
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={
          props?.selectedStatus === "pending"
            ? report?.saveTheChildren?.data?.counts?.pending
            : props?.selectedStatus === "paid"
            ? report?.saveTheChildren?.data?.counts?.paid
            : props?.selectedStatus === "late"
            ? report?.saveTheChildren?.data?.counts?.late
            : report?.saveTheChildren?.total
        }
        count={Math.ceil(
          (props?.selectedStatus === "pending"
            ? report?.saveTheChildren?.data?.counts?.pending
            : props?.selectedStatus === "paid"
            ? report?.saveTheChildren?.data?.counts?.paid
            : props?.selectedStatus === "late"
            ? report?.saveTheChildren?.data?.counts?.late
            : report?.saveTheChildren?.total) / props?.limit
        )}
      >
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          loading={report?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default SavetheChildrentable;
