import React, {useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin, Button, Space } from "antd";
import { IoFilterOutline } from "react-icons/io5";
import { getAllChannelAction } from "../../store/channel/actions";

const SubAccountFilter = (props: any) => {
  const {  channel } = useSelector((state: any) => state);
  const [selectedFilters, setSelectedFilters] = useState(false);
  const { Option } = Select;



  const resetFilter = () => {
    if (selectedFilters) {
      props?.setSelectedStatus("");
      props?.setSelectedChannel("")
    }
  };


  const handleChannelChange = (value: string) => {
    if (!value) {
      props?.setSelectedChannel("");
    } else {
      props?.setSelectedChannel(value);
    }
  };

   return (
    <div className="pl-4 pr-4 pt-2 pb-2 text-[#030229] opacity-90">
      <div className="flex justify-between items-center space-x-2">
        <Button
          className="font-medium"
          style={{
            color: selectedFilters ? "#605BFF" : "",
            borderColor: selectedFilters ? "#605BFF" : "",
          }}
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          <Space>
            <IoFilterOutline size={18} /> <p>Filter</p>
          </Space>
        </Button>

        {selectedFilters && (
          <div className="flex justify-between items-center space-x-2">
            <Select
              size="middle"
              showSearch
              style={{ width: 200, fontSize: "4px" }}
              allowClear
              placeholder="Filter by status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")?.toString()?.toLowerCase()?.includes(input.toLowerCase())
              }
              onClear={() => props?.setSelectedStatus("")}
              onChange={(value) => props?.setSelectedStatus(value || "")}
            >
              {["active", "inactive"].map((status) => (
                <Option key={status} value={status} label={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </div>
        )}

        {selectedFilters && (
          <div className="flex justify-between items-center space-x-2">
            <Select
              size="middle"
              showSearch
              style={{ width: 200, fontSize: "4px" }}
              allowClear
              placeholder="Filter by Channel"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")?.toString()?.toLowerCase()?.includes(input.toLowerCase())
              }
              loading={channel.isFetching}
              disabled={channel.isFetching}
              onClear={resetFilter}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "")?.toString()?.toLowerCase())
              }
              onChange={handleChannelChange}
            >
              {channel?.all?.data?.map((data: any) => (
                <Option key={data?._id} value={data?._id} label={data?.name}>
                  {data?.name}
                </Option>
              ))}
            </Select>
          </div>
        )}

       
      </div>
    </div>
  )
};
export default SubAccountFilter;
